/**=====================
 custom CSS Start
==========================**/
// you can add your custom css here

.row-added-item-form {
  border-bottom: 1px solid #9099a2;
  color: #9099a2;
  .btn {
    padding: 0;
    margin-left: 10px;
    margin-top: -5px;
    i {
      color: #9099a2;
      font-size: 20px;
      &:hover {
        color: $primary-color;
      }
    }
  }
  span {
    font-weight: 600;
  }
}

.footer-sweetalert {
  img {
    max-width: 80%;
  }
  .bar-dx {
    height: 50px;
    background: #0d9a71;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -25px;
      width: 50px;
      height: 50px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid #fff;
    }
  }
}

.modal-body {
  padding: 0px;
  background-color: #fff;
  border: 4px solid #51c8a5;
}

.form-control {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0;
}

.form-select {
  border-radius: 0;
}

div:where(.swal2-container) .swal2-html-container {
  padding: 2em 1.6em 1em !important;
}
button.swal2-close {
  background: $primary-color;
  color: #fff;
  border-radius: 0;
  margin: 5px;
}

.checkbox.m-squar {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input[type='checkbox'] {
    position: absolute;
    left: 0;
    width: 25px;
    height: 25px;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    &:checked ~ .checkmark {
      background-color: $primary-color;
    }
    &:checked ~ .checkmark:after {
      display: block;
    }
    &:disabled ~ .checkmark {
      background-color: $light-gray !important;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #f1f6fa;
    &:after {
      content: '';
      position: absolute;
      display: none;

      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.wrap-icon-toggle-menu {
  padding: 10px 20px 0 20px;
  img {
    cursor: pointer;
    width: 30px;
    filter: invert(64%) sepia(7%) saturate(410%) hue-rotate(169deg) brightness(93%) contrast(89%);
  }
}

.form-select:disabled {
  background-image: none;
}
ng-select.ng-select-multiple.ng-select-searchable.ng-select-clearable.ng-select.ng-select-disabled.ng-untouched.ng-pristine {
  span.ng-arrow-wrapper {
    display: none;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'] {
  //background:none;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}
.was-validated .ng-invalid ~ .invalid-feedback {
  display: block !important;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'] {
  --bs-form-select-bg-icon: unset !important;
  background-image: none;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size='1'],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size='1'] {
  --bs-form-select-bg-icon: unset !important;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  // visibility: hidden;
}

.ng-select .ng-select-container {
  min-height: 45px !important;
}

.pointer {
  cursor: pointer;
}
.section-auth-container {
  background: transparent linear-gradient(180deg, #52c8a5, #0d9a71) 0% 0% no-repeat padding-box;
}
ng-select.ng-select-searchable.ng-select-clearable.ng-select.ng-select-single.ng-untouched.ng-pristine.ng-select-disabled {
  .ng-arrow-wrapper {
    display: none;
  }
}

ngb-datepicker-navigation-select.ngb-dp-navigation-select {
  select.form-select {
    padding: 0 !important;
  }
}

table.table-activity {
  tr:nth-child(odd) {
    background-color: #fff !important;
  }
  .tr-with-table {
    table {
      tr,
      tr:nth-child(odd) {
        background: rgba(14, 154, 113, 0.09) !important;
      }
    }
  }
}

.disabled-checkbox {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

::ng-deep ngb-tooltip-window {
  opacity: 1 !important;
}

// TOOLTIP
.alfa-tooltip .tooltip-inner {
  background-color: #0d9a72 !important;
  color: #fff !important;
}
.alfa-tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #0d9a72;
}
.alfa-tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #0d9a72;
}
.alfa-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #0d9a72;
}
.alfa-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #0d9a72;
}

.info-tooltip .tooltip-inner {
  background-color: #0d9a72ff !important;
  color: #fff !important;
}
.info-tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #0d9a72;
}
.info-tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #0d9a72;
}
.info-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #0d9a72;
}
.info-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #0d9a72;
}

// END TOOLTIP

.icon-sidebar.active {
  filter: invert(37%) sepia(95%) saturate(248%) hue-rotate(121deg) brightness(90%) contrast(70%);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  position: absolute;
  top: 12px;
  z-index: 999 !important;
}
