/**=====================
  2.9 Buttons CSS start
==========================**/
.btn-link {
  color: var(--theme-deafult);
}

.purchase-btn {
  background: linear-gradient(90deg, var(--theme-deafult) -18.33%, #563ee0 62.78%);
  padding: 12px 20px;
  min-width: 200px;
  border: none;
  transition: 0.5s;

  &:hover {
    background: linear-gradient(90deg, #563ee0 -18.33%, var(--theme-deafult) 62.78%);
    transition: 0.5s;
  }

  @media (max-width: 991px) {
    min-width: 160px;
  }

  @media (max-width: 767px) {
    min-width: 140px;
    padding: 10px 17px;
    font-size: 16px;
  }

  @media (max-width: 575px) {
    font-size: 14px;
  }
}

.btn-light {
  &:not([disabled]):not(.disabled).active.active {
    color: $theme-body-font-color;
    background-color: darken($light-color, 2%) !important;
  }
}

@mixin btn-gradien($deg, $leftcolor, $leftcolor-per, $rightcolor, $rightcolor-per, $font-color) {
  background-image: linear-gradient(to right, $leftcolor 0%, $rightcolor 51%, $leftcolor 100%);
  border: none;
  color: $font-color;
  background-size: auto 200%;
  transition: all 0.3s ease;
}

.btn-primary-gradien {
  @include btn-gradien(60, lighten($primary-color, 8%), 0%, darken($primary-color, 8%), 100%, $white);
  color: $white;

  &:focus,
  &:active,
  &.active {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($primary-color, 8%), 0%, darken($primary-color, 8%), 100%, $white) !important;
  }
}

.btn-secondary-gradien {
  @include btn-gradien(60, lighten($secondary-color, 8%), 0%, darken($secondary-color, 8%), 100%, $white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($secondary-color, 8%), 0%, darken($secondary-color, 8%), 100%, $white) !important;
  }
}

.btn-success-gradien {
  @include btn-gradien(60, lighten($success-color, 8%), 0%, darken($success-color, 8%), 100%, $white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($success-color, 8%), 0%, darken($success-color, 8%), 100%, $white) !important;
  }
}

.btn-danger-gradien {
  @include btn-gradien(60, lighten($danger-color, 8%), 0%, darken($danger-color, 8%), 100%, $white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($danger-color, 8%), 0%, darken($danger-color, 8%), 100%, $white) !important;
  }
}

.btn-warning-gradien {
  @include btn-gradien(60, lighten($warning-color, 8%), 0%, darken($warning-color, 8%), 100%, $white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($warning-color, 8%), 0%, darken($warning-color, 8%), 100%, $white) !important;
  }
}

.btn-info-gradien {
  @include btn-gradien(60, lighten($info-color, 8%), 0%, darken($info-color, 8%), 100%, $white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($info-color, 8%), 0%, darken($info-color, 8%), 100%, $white) !important;
  }
}

.btn-light-gradien {
  @include btn-gradien(60, lighten($light-color, 8%), 0%, darken($light-color, 8%), 100%, $white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($light-color, 8%), 0%, darken($light-color, 8%), 100%, $white) !important;
  }
}

.btn-dark-gradien {
  @include btn-gradien(60, lighten($dark-color, 8%), 0%, darken($dark-color, 8%), 100%, $white);

  &:hover,
  &:focus,
  &:active,
  &.active,
  &.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($dark-color, 8%), 0%, darken($dark-color, 8%), 100%, $white) !important;
  }
}

@mixin btn-squre($border-radius) {
  border-radius: $border-radius + px;
}

@mixin btn-pill($border-radius) {
  border-radius: $border-radius + px;
}

@mixin btn-pill-first($radius) {
  border-radius: $radius + px 0 0 $radius + px;
}

@mixin btn-pill-last($radius) {
  border-radius: 0 $radius + px $radius + px 0;
}

.btn {
  padding: $btn-padding;
  max-height: 40px;
  font-weight: 400;

  &.bg-light-primary {
    &:hover {
      background: $primary-color !important;
      color: $white !important;
    }
  }

  &.bg-light-secondary {
    &:hover {
      background: $secondary-color !important;
      color: $white !important;
    }
  }

  &.bg-light-warning {
    &:hover {
      background: $warning-color !important;
      color: $white !important;
    }
  }

  &.bg-light-tertiary {
    &:hover {
      background: $tertiary-color !important;
      color: $white !important;
    }
  }

  @media (max-width: 767px) {
    font-size: 10px;
  }
}

.btn-lg {
  font-size: $btn-lg-font-size;
}

.btn-sm {
  font-size: $btn-sm-font-size;
}

.btn-xs {
  padding: $btn-xs-padding;
  font-size: $btn-xs-font-size;
}

.large-btn {
  .btn {
    margin-right: 5px;
    margin-bottom: 15px;
  }

  margin-bottom: -20px;
}

.btn-outline-white {
  border: 1px dashed $white;
  color: $white;

  &:hover,
  &:focus {
    background: $white;
  }
}

// outline buttons//
@each $btn-name, $btn-color in (primary, $primary-color), (secondary, $secondary-color), (success, $success-color), (danger, $danger-color),
  (tertiary, $tertiary-color), (info, $info-color), (light, $light-color), (dark, $dark-color), (warning, $warning-color)
{
  .btn-air-#{$btn-name} {
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;

    &:hover,
    &:active,
    &:not([disabled]):not(.disabled):active {
      background-color: darken($btn-color, 10%);
      border-color: darken($btn-color, 10%);
    }
  }

  .input-air-#{$btn-name} {
    box-shadow: 0 3px 5px 1px rgba($btn-color, 0.1) !important;

    &:focus {
      border-color: $btn-color;
    }
  }
}

.btn-gradient {
  color: $white !important;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 5px;
  background: $gradient-primary;
  letter-spacing: 0.06rem;

  &:hover {
    background-size: 100% !important;
  }
}

.btn-warning {
  color: $white;

  &:hover,
  &.disabled {
    color: $white;
  }
}

[class*='-gradien'] {
  &:hover {
    background-size: 50% 100%;
    transition: all 0.3s ease;
    color: $white;
  }
}

.btn-square {
  @include btn-squre(0);
}

.btn-pill {
  @include btn-squre(60);
}

.btn-group-pill {
  .btn {
    &:first-child {
      @include btn-pill-first(60);
    }

    &:last-child {
      @include btn-pill-last(60);
    }
  }
}

.btn-group-square {
  .btn {
    &:first-child {
      @include btn-pill-first(0);
    }

    &:last-child {
      @include btn-pill-last(0);
    }
  }
}

.btn-showcase {
  .btn {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  margin-bottom: -10px;
}

.btn-dropdown-showcase {
  .dropdown {
    margin-bottom: 10px;
    margin-right: 18px;
  }

  .btn-group {
    margin-bottom: 10px;
    margin-right: 18px;
  }

  margin-bottom: -10px;
}

.btn-group {
  .radio {
    label {
      &::before {
        top: 2px;
      }

      &::after {
        top: 7px;
      }
    }
  }

  .checkbox {
    label {
      margin-top: 3px;
    }
  }
}

.btn-group-showcase {
  > div {
    margin-bottom: -20px;

    label {
      margin-bottom: 0;
    }
  }

  .btn-group {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .checkbox {
    input[type='checkbox'] {
      display: none;
    }
  }
}

.tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: #555;
  color: $white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: dashed;
    border-color: #555 transparent transparent transparent;
  }
}

// Default button on hover
.card-header-right-icon {
  .btn {
    &.badge-light-primary {
      &:hover {
        color: var(--white);
      }
    }
  }
}

// Button Group
// .btn-radio {
//   .btn-group {
//     .btn {
//       padding: 8px 16px;

//       .radio {
//         label {
//           [dir="rtl"] & {
//             padding-left: unset;
//             padding-right: 5px;
//           }
//         }
//       }
//     }
//   }
// }

.button-icon-filter,
.small-square-primary {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 0;
  border-radius: 0;

  svg {
    max-width: 50%;
  }
}

.button-icon-filter {
  border: 1px solid #f4f7f9;

  img {
    max-width: 50%;
  }

  svg {
    path {
      fill: #9099a2;
    }
  }
}

.button-icon {
  img {
    max-width: 50%;
    height: 50%;
    max-height: 50%;
  }
}

.small-square-primary {
  background-color: $base-template-color;
  color: $white;
  border-radius: 0px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $base-template-color;
    color: #fff;
  }
  svg.svg-font-awesome {
    //width: 30px;
    path {
      fill: #fff;
    }
  }
}

.small-delete-icon {
  border-radius: 0px;
  padding: 0 !important;
}

.small-cta-icon {
  width: 13px;
  margin-right: 10px;
}

.toggle-row-button {
  border-radius: 0px;
  padding: 0 !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
  }
}

/**=====================
    2.9 Buttons CSS end
==========================**/
