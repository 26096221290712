/**=====================
    2.22 Modal CSS start
==========================**/

.modal-toggle-wrapper {
  .form-control {
    &::placeholder {
      font-size: 13px;
    }
  }

  .modal-img {
    display: flex;
    justify-content: center;

    img {
      width: 200px;
      height: 200px;

      @media (max-width: 992px) {
        width: calc(136px + (200 - 136) * ((100vw - 320px) / (992 - 320)));
        height: calc(136px + (200 - 136) * ((100vw - 320px) / (992 - 320)));
      }
    }

    li {
      img {
        width: 100px;
        height: 100px;

        @media (max-width: 992px) {
          width: calc(75px + (100 - 75) * ((100vw - 320px) / (992 - 320)));
          height: calc(75px + (100 - 75) * ((100vw - 320px) / (992 - 320)));
        }
      }
    }

    li + li {
      margin-left: -39px;
    }
  }

  button {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.balance-item {
  display: flex;
  gap: 10px;

  .balance-icon-wrap {
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    @media (max-width: 1660px) {
      width: 25px;
      min-width: 25px;
      height: 25px;
      border-radius: 8px;
    }

    .balance-icon {
      width: 16px;
      height: 16px;
      border-radius: 100%;

      @media (max-width: 1660px) {
        width: 14px;
        height: 14px;
      }

      svg {
        color: $white;
        width: 14px;
        height: 14px;

        @media (max-width: 1660px) {
          width: 12px;
          height: 12px;
          vertical-align: text-top;
        }
      }
    }
  }

  .badge {
    padding: 8px 10px;
  }

  &.danger {
    .balance-icon-wrap {
      background-color: rgba($danger-color, 0.1);

      .balance-icon {
        background-color: $danger-color;
      }
    }
  }

  &.success {
    .balance-icon-wrap {
      background-color: rgba($success-color, 0.1);

      .balance-icon {
        background-color: $success-color;
      }
    }
  }
}

.balance-box {
  text-align: center;
  background-image: url(../../images/dashboard-4/bg-balance.png);
  background-position: right;
  background-size: cover;

  .balance-img {
    display: inline-block;
    padding: 5px;
    border: 2px solid var(--chart-border);
    border-left-color: $success-color;
    border-bottom-color: $success-color;
    border-radius: 100%;
    position: relative;
    margin-bottom: 15px;

    img {
      background-color: var(--balance-profie-bg);
      border-radius: 100%;
    }

    .edit-icon {
      width: 26px;
      height: 26px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--white);
      background-color: #dfdff4;
      right: 0;
      bottom: 0;
      border-radius: 100%;

      svg {
        width: 11px;
        height: 11px;
        fill: $primary-color;
      }
    }
  }
}

.balance-profile {
  ul {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 16px;

    @media (max-width: 1660px) {
      gap: 25px;
    }

    li {
      position: relative;

      + li {
        &::before {
          position: absolute;
          content: "";
          left: -25px;
          top: 50%;
          height: 40px;
          transform: translateY(-50%);
          border: 1px dashed var(--recent-dashed-border);

          [dir="rtl"] & {
            left: unset;
            right: -25px;
          }

          @media (max-width: 1660px) {
            left: -16px;

            [dir="rtl"] & {
              right: -16px;
            }
          }
        }
      }
    }
  }
}

.large-modal-header,
.large-modal-body {
  display: flex;
  align-items: center;
  color: $dark-color;

  svg {
    width: 20px;
    height: 20px;
  }
}

.modal-padding-space {
  padding-left: 20px;
}

.svg-modal {
  width: 15px;
  height: 15px;
  stroke: $secondary-color;
  vertical-align: middle;
}

.card-wrapper {
  .modal-heading {
    h5 {
      font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1400 - 320)));
    }
  }

  .balance-modal {
    @media (min-width: 750px) and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }
  }
}

.modal-footer {
  .btn {
    margin: 0 4px;
  }
}

.modal-content {
  border-radius: 0px;
  .modal-header {
    .btn-close {
      &:focus {
        box-shadow: unset;
      }
    }
    align-items: unset !important;
  }
}

.modal-details {
  h6 {
    padding-bottom: 12px;
  }

  .web-content {
    margin-bottom: 16px;

    .d-flex {
      p {
        padding-bottom: 8px;
      }

      &:last-child {
        p {
          padding-bottom: 0;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    // +.web-content {
    //     margin-top: 12px;
    // }
  }
}

/**=====================
    2.22 Modal CSS end
==========================**/
