/*
 * Styling for DataTables with Semantic UI
 */

table.dataTable.table {
	margin: 0;

	// Sorting icons using Semantic UI's built in icons (Font Awesome)
	thead th,
	thead td {
		position: relative;

		&.sorting,
		&.sorting_asc,
		&.sorting_desc {
			padding-right: 20px;

			&:after {
				position: absolute;
				top: 12px;
				right: 8px;
				display: block;
				font-family: Icons;
			}
		}

		&.sorting:after {
			content: "\f0dc";
			color: #ddd;
			font-size: 0.8em;
		}
	}

	// Easier calculations
	td,
	th {
		-webkit-box-sizing: content-box;
		box-sizing: content-box;

		&.dataTables_empty {
			text-align: center;
		}
	}

	// Style options for the table. Semantic UI provides its own, but it is also
	// useful to have a few more for DataTables
	&.nowrap {
		th,
		td {
			white-space: nowrap;
		}
	}
}


// DataTables' built in feature elements
div.dataTables_wrapper {
	div.dataTables_length {
		// Match the height of the drop down when Semantic UI JS isn't present
		select {
			vertical-align: middle;
			min-height: 2.7142em;
		}

		.ui.selection.dropdown {
			min-width: 0;
		}
	}

	div.dataTables_filter {
		span.input {
			margin-left: 0.5em;
		}
	}

	div.dataTables_info {
		padding-top: 13px;
		white-space: nowrap;
	}

	div.dataTables_processing {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 200px;
		margin-left: -100px;
		text-align: center;
	}

	div.row.dt-table {
		padding: 0;
	}

	// Merge the three scrolling tables into one
	div.dataTables_scrollHead {
		table.dataTable {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
			border-bottom: none;
		}
	}

	div.dataTables_scrollBody {
		thead { // Hide sort icons
			.sorting:after,
			.sorting_asc:after,
			.sorting_desc:after {
				display: none;
			}
		}

		table.dataTable {
			border-radius: 0;
			border-top: none;
			border-bottom-width: 0;
		}

		table.dataTable.no-footer {
			border-bottom-width: 1px;
		}
	}

	div.dataTables_scrollFoot {
		table.dataTable {
			border-top-right-radius: 0;
			border-top-left-radius: 0;
			border-top: none;
		}
	}
}
