/**=====================
  4.3 Update CSS Start
==========================**/

@import '../utils/variables';

// jsgrid css
.jsgrid-filter-row {
  select,
  input {
    padding: 6px 10px;
    border: 1px dashed $horizontal-border-color;
    background: $white;
  }
}

hr {
  border-style: dashed;
}

.todo-list-header {
  .new-task-wrapper {
    .form-control {
      &.error {
        margin: 0px;
      }
    }
  }
}
.starter-main .alert {
  background-color: rgba(var(--theme-deafult), 0.2) !important;
}

.form-control,
.form-select {
  &:focus {
    border-color: $primary-color;
    box-shadow: 0 0 0 0.25rem rgba($primary-color, 0.3);
  }
}

// popover
.popover {
  background-color: $white;
  border: none;
  -webkit-box-shadow: 0 0 20px rgba($primary-color, 0.1);
  box-shadow: 0 0 20px rgba($primary-color, 0.1);

  .popover-header {
    background-color: $theme-medium-color;
    color: $primary-color;
    border-bottom: none;
  }

  .popover-body {
    color: rgba(43, 43, 43, 0.7);
  }
}

// tooltip css start
.tooltip {
  z-index: 1;

  &.bs-tooltip-top {
    .tooltip-arrow {
      &:before {
        // border-top-color: $theme-medium-color;
      }
    }
  }

  &.bs-tooltip-bottom {
    .tooltip-arrow {
      &:before {
        // border-bottom-color: $theme-medium-color;
        border-top-color: transparent;
      }
    }
  }

  &.bs-tooltip-start {
    .tooltip-arrow {
      &:before {
        // border-left-color: $theme-medium-color;
        border-top-color: transparent;
      }
    }
  }

  &.bs-tooltip-end {
    .tooltip-arrow {
      &:before {
        // border-right-color: $theme-medium-color;
        border-top-color: transparent;
      }
    }
  }

  .tooltip-inner {
    background-color: $theme-medium-color;
    color: $primary-color;
  }

  .tooltip-arrow {
    &:before {
      //   border-top-color: $theme-medium-color;
    }
  }
}

// tooltip css end

// dropdown css start
.dropdown-basic {
  .dropdown {
    .dropdown-content {
      a {
        padding: 6px 16px;
        color: $dark-editor-document;
        opacity: 0.6;
        font-size: 13px;
        border-top: 1px dashed $light-semi-gray;
        background: $white;

        &:hover {
          background-color: $white;
        }
      }

      .dropdown-header {
        padding: 8px 16px;
        font-weight: 400;
        color: $dark-color;
        font-size: 13px;
      }
    }
  }

  .dropup {
    .dropup-content {
      top: auto;
    }
  }
}

// dropdown css end
// accordian css start
.default-according {
  .card {
    .card-header {
      padding: 0;
      border: none;
      border-radius: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      &.bg-primary,
      &.bg-secondary {
        .btn {
          border-color: $transparent-color;
        }
      }

      i {
        position: initial;
        font-size: 20px;
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: 5px;

        [dir='rtl'] & {
          margin-right: unset;
          margin-left: 5px;
        }
      }

      h5 {
        margin-top: 0;
      }

      .btn-link {
        padding: 12px 20px;
        width: 100%;
        font-weight: 600;
        text-align: left;
        letter-spacing: 0.7px;
        font-family: $font-outfit;
        border: 1px dashed $light-semi-gray;
        border-radius: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        [dir='rtl'] & {
          text-align: right;
        }

        @media (max-width: 575px) {
          padding: 12px 15px;
        }
      }
    }

    .card-body {
      padding: 15px 20px;
      line-height: 22px;
      font-size: 14px;
      border-color: $light-semi-gray;
      border-radius: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      color: $dark-color;
    }
  }
}

// accordian css end

// tab-bootstrap css start
.nav-tabs {
  border-bottom-color: $light-semi-gray;

  .nav-bottom {
    .nav-item {
      .nav-link.active {
        border-color: $light-semi-gray;
      }
    }
  }
}

span.twitter-typeahead {
  .league-name {
    font-size: 16px;
    padding: 6px 10px 0;
  }

  .tt-menu {
    float: left;
    width: 100%;
    min-width: 10rem;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: $white;
    background-clip: padding-box;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
  }

  .tt-suggestion {
    color: $dark-editor-document;
    opacity: 0.6;
    font-size: 13px;
    padding: 6px 12px;
    border-top: 1px solid $light-semi-gray;
    background: $white;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $white;
    }

    &.active,
    &:active {
      color: $white;
      background-color: var(--theme-deafult);
    }
  }
}

.nav-tabs {
  .nav-item {
    &.show {
      .nav-link {
        font-weight: 500;
      }
    }
  }

  .nav-link {
    color: $dark-color;

    &.active {
      font-weight: 500;
    }
  }

  &.nav-bottom {
    .nav-item {
      .nav-link {
        &.active {
          border-color: $light-gray $light-gray #fff;
        }
      }
    }
  }
}

.dropdown-menu {
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  border: none;
  padding: 0;
  z-index: 5;

  .dropdown-item {
    color: #2b2b2b;
    opacity: 0.6;
    font-size: 13px;
    padding: 6px 12px;
    border-top: 1px solid $light-semi-gray;
    background: $white;

    &:hover {
      background-color: $white;
    }
  }

  .dropdown-divider {
    margin: 0;
    border-top: 1px solid #cccccc;
  }
}

.collapse-horizontal {
  .card-body {
    width: 350px;
  }
}

// tab-bootstrap css end

.border-tab {
  .nav-tabs {
    .nav-item {
      .nav-link {
        &.active {
          border-bottom: 2px dashed $primary-color;
        }

        &.show {
          border-bottom: 2px dashed $primary-color;
        }

        &:focus {
          border-bottom: 2px dashed $primary-color;
        }
      }
    }
  }
}

.tabbed-card {
  .nav-tabs {
    .nav-item {
      .nav-link {
        top: 17px;
      }
    }
  }
}

.timeliny {
  .timeliny-dot::before {
    font-size: $btn-lg-font-size;
  }
}

.component {
  .input-group {
    .btn {
      line-height: 32px;
      text-transform: capitalize;
    }
  }
}

.login-card {
  .login-main {
    .theme-form {
      label {
        font-size: $body-font-size;
      }

      .show-hide {
        top: 50%;
      }
    }
  }
}

// faq css start
.faq-accordion {
  .card {
    .btn-link {
      svg {
        margin-left: 20px;

        @media (max-width: 575.98px) {
          margin-left: 10px;
        }
      }
    }
  }
}

// faq css end
.job-filter {
  .faq-form {
    .form-control {
      font-size: 14px;
    }

    .search-icon {
      width: 16px;
      height: 16px;
    }
  }
}

// editor css
.note-btn-group {
  .dropdown-toggle {
    &.show {
      ~ .dropdown-menu {
        display: block;
        top: 30px;
      }
    }
  }
}

// owl-carousel css

.carousel {
  .carousel-indicators {
    margin-bottom: 3px;
  }
}

.mouse-wheel {
  .owl-stage-outer {
    .owl-stage {
      width: 5600px !important;

      .owl-item {
        width: 225.333px !important;
      }
    }
  }
}

.carousel-item {
  .carousel-caption {
    width: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 26px 10px;
    background-color: rgba($dark-card-background, 0.51);
  }

  .carousel-opacity {
    background-color: rgba($light-color, 0.51);
  }
}

.carousel {
  .carousel-control-prev-icon {
    width: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    height: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
  }

  .carousel-control-next-icon {
    width: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    height: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
  }
}

// Rating
.rating-header {
  h4 {
    [dir='rtl'] & {
      direction: ltr;
    }
  }
}

// Animated modal
.animate-img {
  .animate-widget {
    img {
      width: 100%;
      height: 350px;
      margin: 0 auto;
      object-fit: cover;
    }
  }
}

.modal-popup {
  &.modal {
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0);

    .modal-dialog {
      .theme-close {
        width: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
        height: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}

.animated-modal {
  .form-select {
    @media (max-width: 442px) {
      width: 100% !important;
    }
  }
}

// TO-DO scss
.task-container {
  .badge {
    line-height: 13px;
  }
}

// starter kit
.starter-main {
  .alert-primary {
    &.inverse {
      padding: 13px 20px 13px 65px;
      border-color: $primary-color;
      color: $dark-color;

      [dir='rtl'] & {
        padding: 13px 65px 13px 20px;
      }

      &:before {
        content: '';
        position: absolute;
        left: 54px;
        width: 0;
        height: 0;
        border-left: 7px dashed $primary-color;
        border-top: 7px dashed transparent;
        border-bottom: 7px dashed transparent;

        [dir='rtl'] & {
          border-right: 7px dashed $primary-color;
          border-left: unset;
        }
      }

      i {
        padding: 17px 20px;
        display: flex;
        align-items: center;
        background-color: $primary-color;
        color: $white;
        border-radius: 3px 0 0 3px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;

        [dir='rtl'] & {
          right: 0;
          left: unset;
        }
      }
    }
  }
}
.todo {
  .notification-popup {
    z-index: 5;
  }
}
.search-page {
  .info-block {
    border-color: rgba($semi-dark, 0.28) !important;
  }
}

// map-js
.map-z-index {
  z-index: 1;
}

.apexcharts-canvas {
  .apexcharts-xaxistooltip-bottom {
    display: none;
  }
}
// calendar
ngb-datepicker {
  border: 0 !important;
  .ngb-dp-header {
    background-color: transparent;
    padding: 0 0 12px;
    .ngb-dp-arrow-btn {
      color: $theme-body-font-color;
      width: 34px;
      height: 34px;
      margin: 0;
      border-radius: 30px;
      span {
        width: 8px;
        height: 8px;
      }
    }
  }
  .ngb-dp-weekday {
    color: $primary-color;
    font-weight: 600;
    font-style: initial;
  }
  .ngb-dp-content {
    .ngb-dp-week {
      .btn-light {
        background-color: transparent !important;
        border-color: transparent !important;
        color: $theme-body-font-color;
        &:hover {
          background-color: $primary-color !important;
          border-color: $primary-color !important;
          color: $white;
          outline: 0;
          &.text-muted {
            color: $white !important;
          }
        }
        &.text-white {
          background-color: $primary-color !important;
          border: 1px solid $primary-color !important;
          color: $white !important;
        }
      }
    }
  }
  .ngb-dp-weekdays {
    background-color: transparent;
    border-bottom: 0;
  }
}
.cal-date-widget {
  .ng-valid {
    width: 100%;
    padding: 0;
    .ngb-dp-month {
      width: 100%;
      .ngb-dp-weekday,
      .ngb-dp-day {
        width: 100%;
        height: 36px;
      }
      .ngb-dp-day {
        > div[ngbdatepickerdayview] {
          width: 100%;
        }
      }
    }
  }
}
.ngb-dp-navigation-select {
  gap: 10px;
}
.widgets-calendar {
  .datepicker-here {
    margin-top: -45px;
  }
}
body {
  &.dark-sidebar {
    .page-wrapper {
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                > li {
                  > a {
                    svg {
                      stroke: $white !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.ngx-slider {
  .ngx-slider-pointer {
    background-color: rgba($primary-color, 1) !important;
  }
  .ngx-slider-selection {
    background: rgba($primary-color, 1) !important;
  }
}
.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: rgba($white, 1) !important;
}
.product-filter {
  .color-selector {
    ul {
      li {
        margin: 0 2px;
        &:first-child {
          margin-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
.product-filter {
  &.new-products {
    .owl-theme {
      .owl-item {
        .item {
          .product-box {
            .product-details {
              padding-top: 0;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
.product-page-main {
  #carousel-container {
    .nav-left,
    .nav-right {
      display: none;
    }
  }
  #dots {
    display: none;
  }
  ks-carousel-previews {
    .nav-left,
    .nav-right {
      display: none;
    }
  }
}
.form-check-input:focus {
  border-color: rgba($primary-color, 1);
  box-shadow: 0 0 0 0.25rem rgba($primary-color, 0.25);
}
.page-link.active,
.active > .page-link {
  background-color: $primary-color;
  border-color: $primary-color;
}
.page-link {
  color: $primary-color;
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba($primary-color, 0.25);
  }
  &:hover {
    color: $primary-color;
  }
}
.order-history {
  table {
    fieldset {
      border: none !important;
      padding: 0 !important;
    }
  }
}
.ck.ck-toolbar {
  border: none !important;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border: none !important;
  border-top: 1px dashed $light-background !important;
}
/**=====================
    4.3 Update CSS Ends
==========================**/
