/**=====================
  3.14 Add-product CSS Start
==========================**/

.sidebar-left-wrapper {
  border-right: 1px dashed var(--recent-dashed-border);

  @media (max-width: 1199px) {
    border-right: unset;
  }

  [dir='rtl'] & {
    border-right: unset;
    border-left: 1px dashed var(--recent-dashed-border);
  }

  .sidebar-left-icons {
    display: block;

    .nav-item {
      position: relative;
      padding-bottom: 50px;

      @media (max-width: 1199px) {
        padding-bottom: 25px;
      }

      .nav-link {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;

        .nav-link {
          padding-bottom: 0;
        }
      }

      &::before {
        content: '';
        position: absolute;
        border: 1px dashed var(--recent-dashed-border);
        top: 58px;
        height: 50px;
        left: 20px;

        [dir='rtl'] & {
          left: unset;
          right: 20px;
        }

        @media (max-width: 1199px) {
          height: 25px;
        }
      }

      &:last-child {
        &::before {
          content: none;
        }

        .nav-link {
          padding-bottom: 0;
        }
      }

      .nav-link {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 8px 0;

        &.active,
        &:active {
          background-color: unset;
          color: unset;

          .nav-rounded {
            border: 1px dashed var(--theme-deafult);

            .product-icons {
              background-color: var(--theme-deafult);

              svg {
                stroke: var(--white);
              }
            }
          }

          .product-tab-content {
            h5 {
              color: var(--theme-deafult);
              font-weight: 600;
            }
          }
        }

        .nav-rounded {
          min-width: 42px;
          height: 42px;
          border: 1px dashed var(--recent-dashed-border);
          position: relative;
          z-index: 2;
          border-radius: 100%;
          padding: 2px;
          box-shadow: inset 0px 9px 20px 0px rgb(46 35 94 / 7%);

          .product-icons {
            min-width: 100%;
            height: 100%;
            background-color: var(--white);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;

            svg {
              width: 18px;
              height: 18px;
              stroke: var(--chart-text-color);
              vertical-align: sub;
            }
          }
        }

        .product-tab-content {
          h5 {
            color: var(--body-font-color);
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            width: 100%;

            @media (max-width: 575px) {
              text-align: left;
            }
          }

          p {
            color: var(--chart-text-color);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            width: 100%;

            @media (max-width: 575px) {
              text-align: left;
            }
          }
        }
      }

      &:hover {
        .nav-rounded {
          border: 1px solid var(--theme-deafult);

          .product-icons {
            background-color: var(--light-shade-primary, 1);

            svg {
              stroke: var(--theme-deafult);
              animation: tada 1.5s ease infinite;
            }
          }
        }

        .product-tab-content {
          h5 {
            color: var(--theme-deafult);
          }
        }
      }
    }
  }
}

// Product
.sidebar-body {
  form {
    .form-control,
    .form-select {
      &:focus {
        box-shadow: unset;
        border-color: unset;
      }
    }
  }

  .ql-snow {
    .ql-picker-label {
      [dir='rtl'] & {
        padding-right: 8px;
        padding-left: 2px;
      }
    }
  }

  .ql-toolbar {
    &.ql-snow {
      .ql-formats {
        @media (max-width: 575px) {
          margin-right: 0 !important;
        }
      }
    }
  }

  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    [dir='rtl'] & {
      right: unset;
      left: 0;
    }
  }

  .form-select {
    [dir='rtl'] & {
      padding: 6px 12px 6px 36px !important;
    }
  }

  .ql-formats {
    border-right: 1px solid var(--recent-dashed-border);

    [dir='rtl'] & {
      border-left: 1px solid var(--recent-dashed-border);
      border-right: unset;
    }

    @media (max-width: 575px) {
      border-right: unset;

      [dir='rtl'] & {
        border-left: unset;
      }
    }

    &:last-of-type {
      border-right: unset;

      [dir='rtl'] & {
        border-left: unset;
      }
    }
  }

  #editor2 {
    min-height: 250px;
  }
}

// Gallery product
.sidebar-body {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: inner-spin-button;
  }

  .form-select,
  .form-control {
    font-size: 15px;
    color: $color-scarpa;
  }

  .product-upload {
    p {
      margin-bottom: 18px;
      line-height: 0;
    }

    &:nth-child(1) {
      margin-bottom: 30px;

      @media (max-width: 400px) {
        margin-bottom: 26px;
      }
    }

    &:nth-child(2) {
      margin-bottom: 65px;

      @media (max-width: 1199px) {
        margin-bottom: 0;
      }
    }

    form {
      svg {
        width: 48px;
        height: 48px;
      }

      h5 {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.8;
      }

      span {
        color: var(--chart-text-color);
        font-size: 14px;
      }
    }
  }
}

.product-buttons {
  margin: 15px 0 0 0;
  text-align: end;
  border-top: 1px dashed var(--recent-dashed-border);
  padding: 12px 0 0 0;
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  left: 50%;
  transform: translateX(-50%);

  [dir='rtl'] & {
    left: unset;
    right: 50%;
    transform: translateX(50%);
  }

  @media (max-width: 1199px) {
    position: unset;
    width: unset;
    transform: unset;

    [dir='rtl'] & {
      transform: unset;
    }
  }

  svg {
    width: 18px;
    height: 18px;
    stroke: var(--chart-text-color);
    vertical-align: -4px;

    @media (max-width: 400px) {
      width: 15px;
      height: 15px;
    }
  }

  .btn {
    border: 1px dashed var(--recent-dashed-border);
    color: var(--chart-text-color);
    font-weight: 500;

    @media (max-width: 1400px) {
      padding: 6px 16px;
    }

    @media (max-width: 400px) {
      padding: 6px 8px;
    }

    &:hover,
    &:active {
      background-color: var(--theme-deafult);
      color: $light;

      svg {
        stroke: $light;
      }
    }
  }
}

.category-button {
  padding-bottom: 30px;
  border-bottom: 1px dashed var(--recent-dashed-border);

  @media (max-width: 575px) {
    padding-bottom: 25px;
  }

  a {
    padding: 12px;
  }
}

.create-category {
  .toolbar-box {
    margin-top: 12px;
  }
}

.product-date {
  .form-control[readonly] {
    background-color: var(--white);
  }
}

.price-wrapper {
  .radio-wrapper {
    justify-content: flex-start;
    margin-top: 4px;

    li {
      max-width: 200px;

      .form-check-input {
        border-radius: 5px;
        background-color: var(--light-background);

        ~ .form-check-label {
          span {
            color: $color-scarpa;
            font-size: 15px;
            position: relative;
          }
        }

        &:checked {
          background-color: var(--light-background);
          border: 1px solid var(--theme-deafult);
          border-radius: 5px;

          ~ .form-check-label {
            span {
              color: var(--theme-deafult);
              position: relative;
            }
          }
        }
      }
    }
  }
}

// Advance options
.advance-options {
  ul {
    border-bottom: 1px solid var(--recent-dashed-border);

    &.border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            color: var(--body-font-color);
            border-bottom: 2px solid transparent;
            transition: all 0.3s ease-in-out;

            &:active,
            &.active {
              border-bottom: 2px solid var(--theme-deafult);
              color: var(--body-font-color);
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }
}

.meta-body {
  padding-top: 16px;

  .custom-input {
    .form-check {
      .form-check-input {
        &:checked {
          background-color: var(--theme-deafult);
        }

        &:valid {
          border-color: var(--theme-deafult);

          ~ {
            .form-check-label {
              color: rgba($theme-body-sub-title-color, 0.8);
            }
          }
        }
      }
    }
  }

  // .add-variation {
  //     .alert-box {
  //         position: fixed;
  //         top: 50%;
  //         left: 50%;
  //         transform: translate(-50%, -50%);
  //         width: 300px;
  //         padding: 20px;
  //         background-color: #f2f2f2;
  //         border: 1px solid #ccc;
  //         display: none;

  //         [dir="rtl"] & {
  //             left: unset;
  //             right: 50%;
  //         }
  //     }

  //     .close-btn {
  //         position: absolute;
  //         top: 10px;
  //         right: 10px;
  //         cursor: pointer;

  //         [dir="rtl"] & {
  //             right: unset;
  //             left: 10px;
  //         }
  //     }
  // }
}

// .additional-description {
//     margin-top: 20px;
// }

.product-tag {
  .tagify {
    border-radius: 4px;
    padding: 2px;
    height: auto;
    width: 100%;
  }
}

.tagify__tag {
  [dir='rtl'] & {
    margin-right: 5px;
    margin-left: 0px;
  }
}

.tagify__tag__removeBtn {
  [dir='rtl'] & {
    margin-right: auto;
    margin-left: 4.6666666667px;
  }
}

// list-product scss

.list-product-header {
  > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    margin-bottom: 14px;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .collapse {
    line-height: 0.2;
  }

  .light-box {
    width: 39px;
    height: 39px;
    border-radius: 5px;
    background-color: var(--light-shade-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;

    svg {
      width: 17px;
      height: 17px;
      color: var(--chart-text-color);
    }
  }

  a {
    &.btn {
      float: right;

      [dir='rtl'] & {
        float: left;
      }

      i {
        padding-right: 8px;

        [dir='rtl'] & {
          padding-right: unset;
          padding-left: 8px;
        }
      }

      @media (max-width: 575px) {
        float: unset;
      }
    }
  }

  .list-product-body {
    margin-top: 20px;
    padding: 0px;
    box-shadow: unset;

    .form-select {
      font-size: 14px;
      border-color: var(--recent-dashed-border);
      color: var(--text-gray);
    }
  }
}

.list-product {
  &.list-category {
    tr {
      th,
      td {
        &:nth-child(2) {
          min-width: 60px;
        }
      }

      th,
      td {
        &:nth-child(3) {
          min-width: 500px;
        }
      }

      th,
      td {
        &:nth-child(4) {
          min-width: 150px;
        }
      }

      th,
      td {
        &:nth-child(5) {
          min-width: 100px;
        }
      }
    }
  }

  thead {
    tr {
      th {
        &:first-child {
          .form-check {
            display: none;
          }
        }
      }
    }
  }

  .datatable-container {
    overflow-x: auto;
    border-bottom: none !important;
  }

  .datatable-dropdown {
    position: relative;

    label {
      color: var(--body-font-color);

      &:before {
        content: '\f107';
        position: absolute;
        top: 6px;
        left: 38px;
        font-family: 'FontAwesome';
        font-size: 16px;
        margin: 0;
        color: var(--text-gray);

        [dir='rtl'] & {
          right: 38px;
          left: unset;
        }
      }
    }
  }

  .datatable-selector {
    appearance: none;
    min-width: 55px;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  .datatable-sorter {
    &::before,
    &::after {
      [dir='rtl'] & {
        right: unset;
        left: 4px;
      }
    }
  }

  tr {
    th,
    td {
      text-align: left;

      [dir='rtl'] & {
        text-align: right;
      }
    }

    th:nth-child(1) {
      .datatable-sorter {
        &::before,
        &::after {
          border: none;
        }
      }
    }

    th,
    td {
      &:nth-child(1) {
        min-width: 20px;
      }
    }

    th,
    td {
      &:nth-child(2) {
        min-width: 110px;

        @media (max-width: 1460px) {
          min-width: 230px;
        }
      }
    }

    th,
    td {
      &:nth-child(3) {
        min-width: 90px;
      }
    }

    th,
    td {
      &:nth-child(4) {
        min-width: 120px;
      }
    }

    th,
    td {
      &:nth-child(5) {
        min-width: 120px;
      }
    }

    th,
    td {
      &:nth-child(6) {
        min-width: 80px;
      }
    }

    th,
    td {
      &:nth-child(7) {
        min-width: 100px;
      }
    }

    th,
    td {
      &:nth-child(8) {
        min-width: 140px;
      }
    }

    th,
    td {
      &:nth-child(9) {
        min-width: 105px;
      }
    }
  }

  &:focus-visible {
    outline: 0;
  }

  .datatable-top {
    display: flex;
    gap: 22px;
    flex-wrap: wrap;
  }

  .datatable-search {
    float: left;

    [dir='rtl'] & {
      float: right;
    }
  }

  .datatable-container {
    &:focus-visible {
      outline: 0;
    }
  }

  .datatable-bottom {
    .datatable-info {
      color: var(--body-font-color);
    }
  }

  .starbox {
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
      fill: var(--chart-text-color);
    }
  }

  .datatable-top {
    padding: 0;
  }

  .datatable-pagination {
    li {
      &.datatable-hidden {
        visibility: visible;

        a {
          color: var(--theme-deafult);
          font-weight: 500;
          opacity: 1;
        }
      }
    }

    .datatable-active {
      .datatable-pagination-list-item-link {
        background-color: var(--theme-deafult);
        color: var(--white);
      }
    }

    .datatable-pagination-list-item-link {
      background-color: rgba(var(--light-shade-primary), 0.02);
      border-radius: 5px;
    }
  }
}

.table {
  &#project-status {
    tbody {
      tr {
        td {
          vertical-align: inherit;
        }
      }
    }
  }
}

.product-names {
  display: flex;
  align-items: center;
  gap: 15px;

  .light-product-box {
    min-width: 49px;
    height: 49px;
    border-radius: 3px;
    background-color: var(--chart-progress-light);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 26px;
    }
  }
}

.product-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
    fill: var(--text-gray);
  }
}

.datatable-input,
.datatable-selector {
  border: 1px solid var(--recent-dashed-border);
  border-radius: 5px;
  background: unset;
}

// .datatable-search {
// position: relative;

// .datatable-input {
//     position: absolute;
//     content: "\f002";
//     top: 50%;
//     right: 10px;
//     -webkit-transform: translateY(-50%);
//     transform: translateY(-50%);
//     border: none;
//     font-family: "FontAwesome";
//     font-size: 18px;
//     margin: 0;

// }
// }

// Category page

/**=====================
  3.14 Add-product CSS Start
==========================**/
