/**=====================
    3.38 Sweet-alert CSS Start
==========================**/

.swal-modal {
    .swal-title {
        font-size: 24px;
    }

    .swal-text {
        font-size: 17px;
    }
}
div:where(.swal2-container) div:where(.swal2-popup){
    padding-bottom: 0px!important;
    border: 4px solid #51c8a5!important;
}
.swal2-footer{
    border-top: 0px;
    padding: 0;
}

/**=====================
    3.38 Sweet-alert CSS ends
==========================**/