/**=====================
  5.7 Sidebar CSS Start
==========================**/

%sidebar-after {
  position: absolute;
  content: "";
  top: 0;
  height: 100%;
  width: 2px;
  background: #9199A2;
  border-bottom: 2px solid #fff;
}

// default dashboard
.d-flex {
  &.media-wrapper {
    width: 100%;
    display: -webkit-flex;
    .flex-grow-1 {
      flex: unset;
    }
  }
}

%sidebar-active-dot {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  top: 50%;
  transform: translateY(-50%);
  left: 43px;
  border-radius: 100%;
  background: rgba($sidebar-font, 0.57);
  [dir="rtl"] & {
    left: unset;
    right: 44px;
  }
}

%sidebar-before {
  height: calc(100% - 12px);
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  position: absolute;
  content: '';
  width: 1px;
  background: rgba($sidebar-font, 0.57);
  top: 6px;
  left: 27px;
  [dir="rtl"] & {
    left: unset;
    right: 27px;
  }
}

%horizontal-after {
  position: absolute;
  content: "";
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
  background-color: #59667a;
}

%for-animated-hover {
  box-shadow: $main-header-shadow;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}

%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  border-radius: 5px;
  overflow: hidden;
  &:before {
    width: 0;
    height: 0;
    border-left: 7px dashed transparent;
    border-right: 7px dashed transparent;
    border-bottom: 7px dashed $white;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }
  &:after {
    width: 0;
    height: 0;
    border-left: 7px dashed transparent;
    border-right: 7px dashed transparent;
    border-bottom: 7px dashed $light-background;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}

.page-wrapper {
  position: relative;
  .page-header {
    max-width: 100vw;
    position: $main-header-position;
    top: $main-header-top;
    z-index: $main-header-z-index;
    transition: $sidebar-transition;
    background-color: $white;
    box-shadow: $main-header-shadow-1;
    padding: $header-wrapper-padding;
    @media (max-width: 1428px) {
      padding: 15px 4px;
    }
    @media (max-width:1199px) {
      padding: 15px;
    }
    .breadcrumb {
      .breadcrumb-item {
        color: $body-color;
        font-weight: 500;
        font-size:12px;
        &:before {
          color: $body-color;
        }
        a {
          svg {
            width: 12px;
            height: 12px;
            stroke: $body-color;
            vertical-align: text-top;
          }
        }
        &.active {
          color: $white;
          font-weight: 700;
        }
      }
    }
    .header-wrapper {
      width: 100%;
      align-items: center;
      position: relative;
      .search-full {
        background-color: $white;
        position: absolute;
        right: 0;
        z-index: 1;
        height: 0;
        width: 0;
        transform: scale(0);
        transition: all 0.3s ease;
        &.open {
          height: 100%;
          width: 100%;
          animation: zoomIn 0.5s ease-in-out;
          transform: scale(1);
          transition: all 0.3s ease;
        }
        input {
          line-height: 30px;
          padding-left: 60px;
          width: 100% !important;
          background-color: $white;
          box-shadow: 0 0 40px rgba($black, 15%);
          &:focus {
            outline: none !important;
          }
        }
        .form-group {
          .close-search {
            position: absolute;
            font-size: 15px;
            right: 30px;
            top: 11px;
            color: $dark-gray;
            cursor: pointer;
            [dir="rtl"] & {
              left: 30px;
              right: unset;
            }
          }
          &:before {
            position: absolute;
            left: 30px;
            top: 11px;
            z-index: 2;
            content: "\f002";
            font-size: 16px;
            color: $dark-gray;
            font-family: FontAwesome;
            [dir="rtl"] & {
              left: unset;
              right: 30px;
            }
          }
        }
      }
      .nav-right {
        justify-content: flex-end;
        ul {
          li {
            svg {
              vertical-align: $header-wrapper-nav-icon-align;
              width: $header-wrapper-nav-icon-size;
              height: 18px;
              stroke: rgba($theme-body-font-color, 0.9);
              //fill: none;
              vertical-align: sub;
              path {
                color: $header-wrapper-nav-icon-color;
              }
            }
            .mode {
              &.active {
                svg {
                  fill: $white;
                }
              }
            }
          }
        }
        &.right-header {
          ul {
            li {
              .mode {
                svg {
                  width: 18px !important;
                  height: 18px !important;
                }
                cursor: pointer;
                text-align: center;
                i {
                  font-size: 18px;
                  font-weight: 600;
                  transition: all 0.3s ease;
                  &.fa-lightbulb-o {
                    font-weight: 500;
                    font-size: 22px;
                    transition: all 0.3s ease;
                    animation: zoomIn 300ms ease-in-out;
                  }
                }
              }
              .header-search {
                cursor: pointer;
              }
              .profile-media {
                .flex-grow-1 {
                  margin-left: 15px;
                  min-width: 84px;
                  span {
                    font-weight: 600;
                    font-size:12px;
                  }
                  p {
                    font-size: 12px;
                    line-height: 1;
                    color: rgba($theme-body-font-color, 0.7);
                  }
                }
                .role-profile {
                  height: $cut-round;
                  width:95px;
                  background: $base-template-color;
                  position: relative;
                  &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: -25px;
                    width: $cut-round;
                    height: $cut-round;
                    background-color: white;
                    border-radius: 50%;
                    border:1px solid $body-color;
                  }
                  &_label{
                    color: $body-color;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                  }
                  &_img{
                    position: absolute;
                    top: 0;
                    right: -27px;
                    width: $cut-round;
                    overflow: hidden;
                    height: $cut-round;
                    border-radius: 50%;
                    z-index: 1;
                  }
                }
              }
            }
          }
        }
        >ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          >li.profile-nav{
            margin-right:20px;
            border:0px;
          }
          >li {
            padding: 10px 12px;
            display: inline-block;
            position: relative;
            border: 1px solid $header-light-bg;
            margin-right: 10px;
            @media (max-width: 1366px) {
              margin-right: 8px;
              [dir="rtl"] & {
                margin-left: 8px !important;
                margin-right: unset;
              }
            }
            @media (max-width:360px) {
              margin-right: 2px;
              [dir="rtl"] & {
                margin-left: 2px !important;
                margin-right: unset;
              }
            }
            .form-group {
              svg {
                margin-right: 8px;
                [dir="rtl"] & {
                  margin-left: 8px;
                  margin-right: unset;
                }
              }
              input {
                &:focus {
                  outline: none;
                }
              }
            }
            [dir="rtl"] & {
              margin-left: 15px;
              margin-right: unset;
            }
            &:last-child {
              background-color: transparent;
              border-radius: 0px;
              margin-right: 0px;
              [dir="rtl"] & {
                margin-left: 15px;
                margin-right: unset;
              }
            }
            &:first-child {
              display: none;
              @media (max-width: 424px) {
                //display: block !important;
              }
            }
            &:nth-child(2) {
              @media (max-width: 424px) {
                display: none !important;
              }
            }
            .badge {
              position: absolute;
              right: -5px;
              top: -9px;
              padding: 2px 4px;
              font-size: 11px;
              font-weight: 700;
              font-family: work-Sans, sans-serif;
            }
            span {
              ul {
                left: inherit;
                right: -10px;
                width: 130px;
                &:before,
                &:after {
                  left: inherit;
                  right: 10px;
                }
                li {
                  display: block;
                  a {
                    font-size: 14px;
                    color: $dark-color;
                    i {
                      margin-left: 10px;
                      font-size: 13px;
                      color: $dark-color;
                    }
                  }
                }
              }
            }
          }
          .flag-icon {
            font-size: 16px;
          }
        }
        .bookmark-flip {
          width: unset !important;
          box-shadow: unset !important;
          background-color: transparent !important;
          overflow: initial;
          &::after,
          &::before {
            display: none;
          }
          .bookmark-dropdown {
            .bookmark-content {
              text-align: center;
              .bookmark-icon {
                width: 52px;
                height: 52px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                background-color: rgba($theme-body-font-color, 0.1);
              }
              span {
                font-family: $font-outfit, $font-serif;
                font-size: 14px;
                font-weight: 600;
                margin-top: 6px;
                color: $theme-body-font-color;
              }
            }
            .Typeahead-menu{
              overflow: auto;
              height: 150px;
            }
          }
        }
        .flip-card {
          width: 300px;
          height: 260px;
          background-color: transparent;
          .flip-card-inner {
            position: relative;
            height: 100%;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;
            .front,
            .back {
              position: absolute;
              backface-visibility: hidden;
              border-radius: 10px;
              overflow: hidden;
              box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
              width: 300px;
              height: 260px;
              button {
                background-color: $transparent-color;
                color: $primary-color;
                border: none;
                &:focus {
                  outline: $transparent-color;
                }
              }
            }
            .front {
              background-color: $white;
              transition: .3s;
              svg {
                stroke: $theme-body-font-color;
                transition: all 0.3s ease;
                &:hover {
                  stroke: var(--theme-deafult);
                  transition: all 0.3s ease;
                }
              }
              .bookmark-dropdown{
                li{
                  &:first-child{
                    height: 130px;
                    overflow: auto
                    ;
                  }
                }
              }
            }
            .back {
              background-color: $white;
              transform: rotateY(180deg);
              li {
                &:first-child {
                  // margin-top: 20px;
                  height: 177px !important;
                }
              }
              button {
                width: 100%;
              }
              .flip-back-content {
                input {
                  border-radius: 25px;
                  padding: 6px 12px;
                  width: 100%;
                  border: 1px dashed #ddd;
                  &:focus {
                    outline: none;
                  }
                }
                .ProfileCard{
                  .ProfileCard-realName{
                    i{
                      &.starred{
                        color: #feb858;
                      }
                    }
                  }
                }
              }
            }
            &.flipped {
              transform: rotateY(180deg);
              .front {
                opacity: 0;
              }
            }
          }
        }
        .onhover-show-div {
          width: 330px;
          top: 50px;
          right: 0;
          left: unset;
          .dropdown-title {
            padding: 14px 20px;
            text-align: center;
            border-bottom: 1px dashed rgba($dropdown-border-color, 0.3);
          }
          ul {
            padding: 15px;
          }
          li {
            padding: 15px;
            p {
              font-size: 13px;
              letter-spacing: 0.3px;
              margin-bottom: 0;
            }
            a {
              text-decoration: none;
              text-transform: uppercase;
              letter-spacing: 0.3px;
              font-family: $font-outfit, $font-serif;
            }
            &+li {
              margin-top: 15px;
            }
            &:last-child {
              padding-bottom: 15px;
            }
            .d-flex {
              position: relative;
              img {
                width: 40px;
                position: relative;
              }
              .status-circle {
                left: 0;
              }
              .flex-grow-1 {
                >span {
                  letter-spacing: 0.8px;
                  padding-right: 10px;
                  display: inline-block;
                }
                h6 {
                  margin: 8px 8px 0px;
                }
                p {
                  margin-bottom: 8px;
                }
              }
            }
          }
          &:before,
          &:after {
            right: 10px !important;
            left: unset !important;
          }
        }
        .notification-box {
          position: relative;
        }
        .cart-box {
          position: relative;
        }
        .cart-dropdown {
          ul {
            padding: 0 0 15px;
          }
          li {
            margin: 0 15px;
            padding: 10px 0;
            .d-flex {
              align-items: center;
              .flex-grow-1 {
                margin-right: 20px;
                [dir="rtl"] & {
                  margin-right: unset;
                  margin-left: 20px;
                }
              }
            }
            &+li {
              margin-top: 0;
              border-top: 1px dashed rgba($dropdown-border-color, 0.1);
            }
            &:last-child {
              padding: 20px 15px;
              border-top: none;
            }
          }
          p {
            line-height: 1.3;
            margin-top: 3px;
            opacity: 0.6;
          }
          .close-circle {
            position: absolute;
            right: 0;
            top: 0;
            a {
              width: 18px;
              height: 18px;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            svg {
              width: 14px;
              stroke: $white;
            }
          }
          .qty-box {
            margin: 5px 0;
            .input-group {
              width: 80px;
              background-color: transparent;
              input {
                background-color: transparent;
              }
              input,
              .btn {
                font-size: 10px;
                font-family: $font-outfit, $font-serif;
                font-weight: 700;
              }
              .btn {
                padding: 0px 7px;
                background-color: $off-white !important;
                border-color: $off-white !important;
                border-radius: 100%;
                font-size: 14px;
                color: $theme-body-font-color;
              }
            }
          }
          .total {
            margin: 0;
            padding: 15px;
            background-color: #f9f9f9;
            border: none;
            h6 {
              font-family: $font-outfit, $font-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 0.5px;
              span {
                font-size: 14px;
              }
            }
          }
          .view-checkout {
            text-decoration: unset;
          }
        }
        .notification-dropdown {
          top: 52px;
          width: 300px;
          right: -20px !important;
          left: unset;
          @media (max-width: 575.98px) {}
          .notification-box {
            .d-flex {
              align-items: center;
              gap: 6px;
              .flex-shrink-0 {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .flex-grow-1 {
                a {
                  text-decoration: none;
                  h6 {
                    color: $theme-body-font-color;
                    font-weight: 600;
                  }
                }
                p {
                  font-size: 14px;
                  color: $theme-font-color;
                }
              }
            }
          }
          .messages {
            .d-flex {
              align-items: center;
              .flex-shrink-0 {
                img {
                  border-radius: 50px;
                }
              }
              .flex-grow-1 {
                a {
                  text-decoration: none;
                  h6 {
                    text-transform: capitalize;
                    padding-bottom: 6px;
                  }
                }
                p {
                  color: $theme-body-font-color;
                }
              }
              span {
                color: $theme-font-color;
                font-size: 13px;
                font-weight: 600;
              }
            }
          }
          li {
            background-color: #f9f9f9;
            border-radius: 5px;
            &:last-child {
              text-align: center;
              background-color: transparent;
            }
          }
          &:before,
          &:after {
            right: 28px !important;
            left: unset !important;
          }
        }
        .profile-dropdown {
          width: 160px;
          top: 57px;
          left: -12px;
          padding: 0 10px;
          li {
            padding: 10px !important;
            a {
              text-decoration: unset;
            }
            &:hover {
              a {
                span {
                  color: var(--theme-deafult);
                  transition: color 0.3s ease;
                }
                svg {
                  stroke: var(--theme-deafult);
                  transition: stroke 0.3s ease;
                  path {
                    stroke: var(--theme-deafult);
                    transition: stroke 0.3s ease;
                  }
                }
              }
            }
            +li {
              margin-top: 0;
            }
            &:last-child {
              padding-top: 10px !important;
            }
            span {
              color: $dark-color;
            }
            svg {
              width: 16px;
              vertical-align: bottom;
              margin-right: 10px;
              stroke: $dark-color;
            }
          }
        }
      }
    }
  }
  .page-body-wrapper {
    background-color: $main-body-color;
    .page-body {
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      transition: 0.5s;
      &:before {
        animation: fadeIn 0.5s ease-in-out;
      }
    }
    .page-title {
      padding: $page-title-padding;
      margin: 0 -27px 28px;
      background-color: $white;
      >.row {
        margin: 0;
        .col-6 {
          padding: 0;
        }
      }
      .row {
        align-items: center;
        h3 {
          font-size: $page-title-font-size;
          margin-bottom: $page-title-margin-bottom;
          text-transform: $page-title-text-tranform;
          small {
            display: $page-small-title-display;
            font-size: $page-small-title-font-size;
            margin-bottom: $page-small-title-margin-bottom;
            text-transform: $page-small-title-text-transform;
            color: $theme-body-sub-title-color;
          }
        }
      }
      nav {
        .breadcrumb {
          background-color: $transparent-color;
          padding: 0;
          margin-bottom: 0;
          align-items: center;
          justify-content: flex-end;
          li {
            font-size: 13px;
            letter-spacing: 1px;
            font-family: $font-outfit, $font-serif;
          }
          .breadcrumb-item {
            &+.breadcrumb-item {
              &:before {
                content: $breadcrumb-content;
              }
            }
            a {
              svg {
                width: 16px;
                height: 16px;
                stroke: $dark-color;
                vertical-align: text-top;
              }
            }
            &.active {
              color: var(--theme-deafult);
              font-weight: 500;
            }
          }
        }
      }
    }
    div.sidebar-wrapper {
      .sidebar-main {
        .sidebar-custom-scroll {
          ::-webkit-scrollbar {
            width: 0;
          }
          .sidebar-links {
            >li {
              a {
                svg {
                  &.stroke-icon {
                    stroke: $theme-font-color;
                  }
                  &.fill-icon {
                    fill: #757589;
                    stroke: none !important;
                  }
                }
                &.active {
                  svg {
                    transition: all 0.3s ease;
                    &.stroke-icon {
                      stroke: $white;
                    }
                    // &.fill-icon {
                    //   fill: $white;
                    // }
                  }
                }
              }
            }
            li {
              a {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
  .sidebar-wrapper {
    &[data-layout="stroke-svg"] {
      .stroke-icon {
        display: inline-block;
      }
      .fill-icon {
        display: none;
      }
    }
    &[data-layout="fill-svg"] {
      .stroke-icon {
        display: none;
      }
      .fill-icon {
        display: inline-block;
        fill: $white;
      }
    }
  }
  &.horizontal-wrapper {
    [data-simplebar] {
      position: relative;
    }
    .footer {
      margin-left: 0;
    }
    .header-logo-wrapper {
      width: 150px;
      .toggle-sidebar {
        display: none;
      }
    }
    .header-wrapper {
      .header-logo-wrapper {
        display: none;
      }
    }
    .sidebar-list {
      i.fa-thumb-tack {
        visibility: hidden;
        position: absolute;
        top: 19px;
        right: 0px;
        color: $primary-color;
        [dir="rtl"] & {
          left: 0;
          right: unset;
        }
      }
      &:hover {
        i.fa-thumb-tack {
          visibility: hidden;
          cursor: pointer;
        }
      }
    }
    .page-body-wrapper {
      .sidebar-wrapper {
        height: 54px;
        line-height: inherit;
        top: 75px;
        background: $white;
        box-shadow: 0px 9px 24px rgba($semi-dark, 0.05);
        overflow-x: hidden;
        z-index: 1;
        overflow-y: visible;
        position: fixed;
        width: 100vw;
        border-top: $card-border-color;
        z-index: 3;
        .simplebar-content {
          display: flex;
        }
        li {
          display: inline-block;
        }
        .badge {
          position: absolute;
          top: 2px;
          right: 0px;
          z-index: 1;
        }
        .logo-wrapper,
        .logo-icon-wrapper {
          display: none;
        }
        .sidebar-main {
          text-align: left;
          position: relative;
          .left-arrow,
          .right-arrow {
            position: absolute;
            top: 0px;
            padding: 15px;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            cursor: pointer;
            &.disabled {
              display: none;
            }
          }
          .left-arrow {
            left: 0;
          }
          .right-arrow {
            right: 0;
          }
          .sidebar-links {
            list-style: none;
            margin: 0;
            padding: 0;
            line-height: normal;
            direction: ltr;
            text-align: left;
            display: -webkit-box !important;
            padding: 0 5px;
            transition: all .5s ease;
            white-space: nowrap;
            li {
              &.sidebar-list {
                a {
                  &.active {
                    .according-menu {
                      i {
                        &:before {
                          content: '-';
                        }
                      }
                    }
                  }
                  .according-menu {
                    display: none;
                  }
                }
                ul.sidebar-submenu {
                  li {
                    a {
                      &.active {
                        color: var(--theme-deafult);
                        &:after {
                          background-color: var(--theme-deafult);
                        }
                      }
                    }
                  }
                }
              }
            }
            >li {
              position: relative;
              padding-top: 10px;
              padding-bottom: 10px;
              &.sidebar-main-title {
                &.pin-title {
                  display: none !important;
                  &.show {
                    display: none !important;
                  }
                }
              }
              &:hover {
                >a {
                  >span {
                    color: var(--theme-deafult);
                    transition: all 0.3s ease;
                  }
                }
                a {
                  background-color: rgba($primary-color, 0.15%);
                  transition: all 0.3s ease;
                  svg {
                    &.stroke-icon {
                      stroke: var(--theme-deafult);
                      transition: all 0.3s ease;
                    }
                    &.fill-icon {
                      fill: var(--theme-deafult);
                      transition: all 0.3s ease;
                    }
                  }
                }
              }
              &:hover {
                .sidebar-submenu {
                  opacity: 1;
                  visibility: visible;
                  display: block !important;
                  top: 130px;
                }
              }
              &:first-child {
                display: none !important;
              }
              >a {
                display: block;
                color: $theme-body-font-color;
                line-height: 23px;
                text-decoration: none;
                text-transform: capitalize;
                padding: 5px 15px;
                margin: 0 3px;
                letter-spacing: 0.04em;
                position: relative;
                border-radius: 5px;
                font-weight: 500;
                transition: all 0.3s ease;
                &.active {
                  background-color: transparent;
                  transition: all 0.3s ease;
                  svg,
                  span {
                    color: var(--theme-deafult);
                    transition: all 0.3s ease;
                  }
                }
                .badge {
                  position: absolute;
                  top: -5px;
                  margin-left: 1px;
                  right: 10px;
                  padding: 0.20em 0.7em;
                }
                svg {
                  width: 18px;
                  height: 18px;
                  vertical-align: sub;
                  stroke: $theme-body-font-color !important;
                  margin-right: 10px;
                }
              }
              .sidebar-submenu {
                position: fixed;
                z-index: 4;
                box-shadow: 0 0 1px 0 $dark-gray;
                padding: 15px 0px 15px 0px;
                background: white;
                li {
                  border: 0;
                  float: none;
                  position: relative;
                  display: block;
                  a {
                    background: transparent;
                    padding: 4px 30px;
                    display: block;
                    transition: all 0.5s ease;
                    line-height: 1.9;
                    color: rgba(43, 43, 43, 0.6);
                    letter-spacing: 0.06em;
                    font-weight: 500;
                    font-family: $font-outfit;
                    transition: all 0.3s ease;
                    &:after {
                      left: 20px;
                      @extend %horizontal-after;
                    }
                    &:hover {
                      color: var(--theme-deafult);
                      letter-spacing: 1.5px;
                      transition: all 0.3s ease;
                      &:after {
                        background-color: var(--theme-deafult);
                      }
                    }
                  }
                }
              }
              .mega-menu-container {
                position: fixed;
                right: 0;
                left: 0;
                margin: 0 auto;
                z-index: 4;
                box-shadow: 0 0 1px 0 $dark-gray;
                padding: 30px;
                background: white;
                width: 95%;
                border-radius: 4px;
                display: block !important;
                .mega-box {
                  width: 20%;
                  .link-section {
                    .submenu-title {
                      h5 {
                        text-transform: uppercase;
                        font-size: 14px;
                        margin-left: -13px;
                      }
                    }
                    .submenu-content {
                      box-shadow: none;
                      background-color: transparent;
                      position: relative;
                      display: block;
                      padding: 0;
                      li {
                        border: 0;
                        float: none;
                        position: relative;
                        display: block;
                        a {
                          border: 0 !important;
                          background: transparent;
                          color: $theme-body-font-color;
                          border-radius: 0 !important;
                          text-transform: capitalize;
                          padding: 3px 0;
                          font-size: 13px;
                          display: block;
                          letter-spacing: 0.07em;
                          line-height: 1.9;
                          position: relative;
                          transition: all 0.3s ease;
                          &:hover {
                            color: var(--theme-deafult);
                            letter-spacing: 1.5px;
                            transition: all 0.3s ease;
                            &:after {
                              color: var(--theme-deafult);
                              transition: all 0.3s ease;
                            }
                          }
                          &:after {
                            position: absolute;
                            content: "";
                            left: -12px;
                            top: 50%;
                            width: 5px;
                            height: 1px;
                            background-color: $light-semi-font;
                          }
                        }
                      }
                    }
                  }
                }
              }
              &:hover {
                >a {
                  &:after {
                    left: 0;
                    right: 0;
                    opacity: 1;
                  }
                }
              }
              &.mega-menu {
                position: unset;
              }
            }
          }
        }
        &.overlay-white {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }
      .toggle-nav {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 24px;
        }
      }
      .page-body {
        margin-top: 165px;
      }
      .sidebar-main-title {
        display: none !important;
      }
      &.scorlled {
        .sidebar-wrapper {
          display: none;
          transition: all 0.3s ease;
        }
      }
    }
    .page-header {
      width: 100%;
      box-shadow: none;
      align-items: center;
      left: 12px;
      .header-wrapper {
        .form-control-plaintext {
          width: auto !important;
        }
        .left-header {
          width: 30%;
          @media (max-width: 1428px) and (min-width: 1324px) {
            width: 32%;
          }
          @media (max-width: 1323px) and (min-width: 1200px) {
            width: 37%;
          }
          @media (max-width: 1199px) and (min-width: 992px) {
            width: 32%;
          }
        }
        .nav-right {
          @media (max-width: 1428px) and (min-width: 1324px) {
            width: 55%;
          }
          @media (max-width: 1323px) and (min-width: 1200px) {
            width: 40%;
          }
          @media (max-width: 1199px) and (min-width: 992px) {
            width: 50%;
          }
        }
      }
    }
    &.enterprice-type {
      footer {
        margin-left: 0;
      }
    }
  }
  &.material-icon {
    .card {
      border-radius: 30px;
      box-shadow: 0 0 20px rgba($primary-color, 0.10);
    }
  }
  .sidebar-main-title {
    h6 {
      font-size: 14px;
      font-weight: 600;
      color: $white;
      text-transform: uppercase;
      margin-bottom: 0;
      letter-spacing: 0.40px;
    }
  }
  .dark-sidebar {
    .page-wrapper {
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                .simplebar-content {
                  >li {
                    >a {
                      svg {
                        stroke: $white !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } // left sidebar css
  &.compact-wrapper {
    .sidebar-list {
      i.fa-thumb-tack {
        visibility: hidden;
        position: absolute;
        top: 11px;
        right: -9px;
        color: $primary-color;
        z-index: 9999;
        [dir="rtl"] & {
          left: -9px;
          right: unset;
        }
      }
      &:hover {
        i.fa-thumb-tack {
          visibility: visible;
          cursor: pointer;
        }
      }
      &.pined {
        order: -1;
      }
    }
    .pin-title {
      order: -1;
    }
    .page-header {
      margin-left: $sidebar-compact-width;
      width: calc(100% - $sidebar-compact-width);
      border-bottom: 2px solid $base-template-color;
      height: $height-header;
      .header-logo-wrapper {
        display: none;
      }
      &.close_icon {
        margin-left: 77px;
        width: calc(100% - 77px);
      }
      .header-wrapper {
        .logo-wrapper {
          display: none;
        }
        .toggle-sidebar {
          display: none;
        }
      }
      .page-title.wrap-breadcumbs{
        background: #fff;
        color: #9099A2;
        @media screen and (min-width: 992px){
          background: $base-template-color;
          color: #fff;
          height: $cut-round;
          position: relative;
          padding: 5px 30px 5px 50px;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: -25px;
            width: $cut-round;
            height: $cut-round;
            background-color: white;
            border-radius: 50%;
            border:1px solid $body-color;
          }
        }

        h4{
          font-size:14px;
          color:$body-color;
          margin-bottom:5px;
        }
        nav{
          ol.breadcrumb{
            li.breadcrumb-item{}
          }
        }
      }
    }
    .page-body-wrapper {
      div {
        &.sidebar-wrapper {
          position: fixed;
          top: 0;
          z-index: 9;
          height: auto;
          line-height: inherit;
          width: $sidebar-compact-width;
          text-align: left;
          transition: 0.3s;
          border-right: 2px solid $base-template-color;
          background: $white;
          .logo-wrapper{
            img{
              max-width:90%;
              height:auto;
            }
          }
          .logo-wrapper,
          .logo-icon-wrapper {
            padding: 18px 14px;
            box-shadow: -9px 0 20px rgba($semi-dark, 0.1);
            background-color: $white;
            height: $height-header;
            border-bottom: 2px solid $base-template-color;
            border-right: 2px solid #fff;
            position: relative;
            &:before{
              content:'';
              width: 3px;
              height: 100%;
              position: absolute;
              top: 0;
              right: -4px;
              background: #fff;
            }
          }
          .logo-icon-wrapper {
            display: none;
            a{
              img{
                max-width:50px;
              }
            }
          }
          &.close_icon {
            width: auto;
            transition: all 0.3s ease;
            .badge {
              opacity: 0;
              width: 0;
              padding: 0;
            }
            .sidebar-main {
              .sidebar-links {
                >li {
                  max-width: 86px;
                  margin: 0;
                  transition: all 0.3s ease;
                  .sidebar-submenu {
                    a {
                      //display: none;
                      padding: 10px 10px;
                    }
                  }
                  a {
                    text-align: center;
                    padding: 12px 14px;
                    transition: all 0.3s ease;
                  }
                }
              }
            }
            .sidebar-main-title {
              display: none !important;
              opacity: 0;
              transition: all 0.3s ease;
            }
            .logo-wrapper {
              display: none;
            }
            .logo-icon-wrapper {
              text-align: right;
              display: block;
            }
            /*&:hover {
              width: 265px;
              transition: all 0.3s ease;
              .badge {
                opacity: 1;
                width: auto;
                transition: all 0.3s ease;
              }
              .sidebar-main {
                .sidebar-links {
                  >li {
                    max-width: unset;
                    margin: initial;
                    transition: all 0.3s ease;
                    a {
                      text-align: left;
                      [dir="rtl"] & {
                        text-align: right;
                      }
                    }
                    .sidebar-submenu {
                      a {
                        display: block;
                      }
                    }
                    >a {
                      padding: 12px 15px 12px 20px;
                      transition: all 0.3s ease;
                      span {
                        display: inline-block;
                      }
                    }
                  }
                }
              }
              .sidebar-main-title {
                opacity: 1;
                display: block !important;
                transition: all 0.3s ease;
                &.pin-title {
                  display: none !important;
                  &.show {
                    display: block !important;
                  }
                }
              }
              .logo-wrapper {
                display: block;
              }
              .logo-icon-wrapper {
                display: none;
              }
            }*/
            .mobile-sidebar {
              transform: translate(183px);
            }
            ~.page-body,
            ~footer {
              margin-left: 80px;
              transition: 0.5s;
            }
            ~.footer-fix {
              width: calc(100% - 84px);
            }
            .sidebar-main {
              .sidebar-links {
                height: calc(100vh - 103px);
                >li {
                  .sidebar-link {
                    &::before {
                      right: 20px;
                      left: unset;
                    }
                  }
                  a {
                    span {
                      display: none;
                    }
                  }
                }
                .sidebar-list {
                  .sidebar-title {
                    &.active {
                      ~.sidebar-submenu {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
            .according-menu {
              display: none;
            }
          }
          .sidebar-main {
            .left-arrow,
            .right-arrow {
              display: none;
            }
            .sidebar-list {
              i.fa-thumb-tack {
                visibility: hidden;
                position: absolute;
                top: 16px;
                right: 35px;
                color: $white;
                [dir="rtl"] & {
                  left: 35px;
                  right: unset;
                }
              }
              &:hover {
                i.fa-thumb-tack {
                  visibility: visible;
                  cursor: pointer;
                }
              }
              &.pined,
              &.pin-title {
                order: -1;
              }
            }
            .pin-title {
              order: -1;
            }
            .sidebar-links {
              height: $sidebar-height;
              left: -300px;
              z-index: 99;
              transition: color 1s ease;
              overflow: auto;
              color: rgba(0, 0, 0, 0);
              margin-top: 6px;
              margin-bottom: 30px;
              display: flex;
              flex-direction: column;
              li {
                a {
                  font-weight: 500;
                  svg {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                    vertical-align: -2px;
                    float: none;
                    transition: all 0.3s ease;
                  }
                  span {
                    letter-spacing: 0.7px;
                    text-transform: capitalize;
                    color: $theme-font-color;
                  }
                  &.link-nav {
                    .according-menu {
                      display: none;
                    }
                  }
                }
                &:last-child {
                  border-bottom: none;
                }
              }
              &.opennav {
                left: 0;
              }
              .back-btn {
                display: block;
                width: 100%;
                padding: 0;
                .mobile-back {
                  padding: 20px;
                  color: $theme-font-color;
                  font-weight: 700;
                  text-transform: uppercase;
                  border-bottom: 1px dashed #efefef;
                  cursor: pointer;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                }
              }
              >li {
                display: block;
                width: 100%;
                position: relative;
                &.sidebar-main-title {
                  //padding: 25px 0 12px 20px;
                  padding-top:10px;
                  div {
                    padding: 6px 10px;
                    width: fit-content;
                    background-color: $light-sidebar-bg;
                    border-radius: 6px;
                  }
                  &.pin-title {
                    display: none;
                    &.show {
                      display: block;
                    }
                  }
                  [dir="rtl"] & {
                    padding: 25px 20px 12px 0;
                  }
                  &:nth-child(2) {
                    margin-top: 0;
                    &::before {
                      display: none;
                    }
                  }
                }
                &.sidebar-list {
                  .badge {
                    position: absolute;
                    right: 50px;
                    top: 14.5px;
                    padding: 0.45em 0.8em 0.33em;
                    z-index: 1;
                    font-size: 10px;
                    letter-spacing: 0.7px;
                    font-weight: 600;
                    font-family: $font-outfit, $font-serif;
                    border-radius: 10px;
                  }
                  &:hover {
                    >a {
                      &:hover {
                        background-color: $second-template-color;
                        transition: all 0.3s ease;
                        .icon-sidebar{
                          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(194deg) brightness(105%) contrast(105%);
                        }
                        span{color:$white!important;}
                      }
                    }
                  }
                }
                &:hover {
                  .sidebar-link:not(.active) {
                    &:hover {
                      svg {
                        fill: rgba($primary-color, 0.10%);
                        stroke: $white;
                        transition: all 0.3s ease;
                        &.fill-icon {
                          fill: $white;
                          stroke: none;
                        }
                      }
                      span {
                        color: $base-template-color;
                        transition: all 0.3s ease;
                      }
                      .according-menu {
                        i {
                          color: $white;
                          transition: all 0.3s ease;
                        }
                      }
                    }
                  }
                }
                a {
                  padding: 12px 20px;
                  position: relative;
                  color: #222222;
                  transition: all 0.3s ease;
                  .sub-arrow {
                    right: 20px;
                    position: absolute;
                    top: 10px;
                    i {
                      display: none;
                    }
                    &:before {
                      display: none;
                    }
                  }
                }
                &:first-child {
                  display: none !important;
                }
                .sidebar-submenu {
                  width: 100%;
                  padding: 0;
                  position: relative !important;
                  &::before {
                    @extend %sidebar-before;
                  }
                  >li {
                    a {
                      padding-left: 62px !important;
                      font-weight: 400;
                    }
                  }
                  .submenu-title {
                    .according-menu {
                      top: 7px;
                    }
                  }
                  &.opensubmenu {
                    display: block;
                  }
                  li {
                    &:hover {
                      >a {
                        color: $theme-font-color;
                        transition: all 0.3s ease;
                      }
                    }
                    .nav-sub-childmenu {
                      display: none;
                      position: relative !important;
                      right: 0;
                      width: 100%;
                      padding: 0;
                      &.opensubchild {
                        display: block;
                      }
                      li {
                        a {
                          padding: 6px 45px;
                          padding-left: 56px !important;
                          &:after {
                            display: none;
                          }
                        }
                      }
                    }
                    a {
                      padding: 7px 25px;
                      font-size: 14px;
                      color: rgba($sidebar-font, 0.57);
                      display: block;
                      position: relative;
                      letter-spacing: 0.06em;
                      font-weight: 500;
                      transition: all 0.3s ease;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      &::before {
                        //@extend %sidebar-active-dot;
                      }
                      &.active {
                        color: $base-template-color;
                        &::before {
                          background: $base-template-color;
                        }
                        &:after {
                          background: $base-template-color;
                        }
                      }
                      &:after {
                        left: 27px;
                        @extend %sidebar-after;
                      }
                      &:hover {
                        margin-left: 0;
                      }
                    }
                  }
                }
                .mega-menu-container {
                  padding: 0;
                  position: relative;
                  &::before {
                    @extend %sidebar-before;
                  }
                  &.opensubmenu {
                    display: block;
                  }
                  .mega-box {
                    width: 100%;
                    padding: 0;
                    .link-section {
                      &:hover {
                        h5 {
                          color: $white !important;
                          transition: all 0.3s ease;
                        }
                      }
                      .submenu-title {
                        position: relative;
                        h5 {
                          margin-bottom: 0;
                          line-height: 1.9;
                          padding: 6px 25px 6px 62px;
                          font-size: 14px;
                          position: relative;
                          color: rgba($white, 0.57);
                          display: block;
                          letter-spacing: 0.06em;
                          font-weight: 500;
                          font-family: $font-outfit;
                          cursor: pointer;
                          [dir="rtl"] & {
                            padding: 6px 62px 6px 25px;
                          }
                          &:after {
                            left: 28px;
                            @extend %sidebar-after;
                            [dir="rtl"] & {
                              right: 28px;
                              left: unset;
                            }
                          }
                          &::before {
                            @extend %sidebar-active-dot;
                          }
                        }
                        &.active {
                          h5 {
                            color: $white;
                            &::before {
                              opacity: 1;
                              visibility: visible;
                              background-color: $white;
                            }
                            &::after {
                              border-top-color: $white;
                            }
                          }
                        }
                        .according-menu {
                          top: 8px;
                        }
                      }
                      .submenu-content {
                        &.opensubmegamenu {
                          li {
                            a {
                              margin-bottom: 0;
                              line-height: 1.9;
                              padding: 6px 25px 6px 76px;
                              font-size: 14px;
                              position: relative;
                              color: rgba($white, 0.57);
                              display: block;
                              letter-spacing: 0.06em;
                              font-weight: 500;
                              font-family: $font-outfit;
                              [dir="rtl"] & {
                                padding: 6px 76px 6px 25px;
                              }
                              &:after {
                                display: none;
                              }
                              &.active {
                                color: $white;
                              }
                            }
                          }
                        }
                        ul {
                          li {
                            a {
                              line-height: 1.9;
                              &:hover {
                                margin-left: 0;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &.mega-menu {
                  .sidebar-title {
                    &.active {
                      ~.mega-menu-container {
                        display: block !important;
                        &::before {
                          height: calc(100% - 9px);
                          transition: height 0.5s;
                        }
                      }
                    }
                  }
                }
                .sidebar-link {
                  transition: all 0.5s ease;
                  display: block;
                  .icon-sidebar{
                    width: 30px;
                    margin-right: 10px;
                    filter: invert(64%) sepia(7%) saturate(410%) hue-rotate(169deg) brightness(93%) contrast(89%);
                  }
                  &::after {
                    position: absolute;
                    content: '';
                    width: 4px;
                    height: 35px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: $primary-color;
                    border-radius: 5px 0px 0px 5px;
                    opacity: 0;
                    visibility: hidden;
                    [dir="rtl"] & {
                      right: unset;
                      left: 0;
                    }
                  }
                  &.active {
                    transition: all 0.5s ease;
                    position: relative;
                    background-color: $primary-color;
                    svg{
                      stroke: #fff;
                    }
                    &::after {
                      opacity: 1;
                      visibility: visible;
                    }
                    ~.sidebar-submenu {
                      &::before {
                        height: calc(100% - 12px);
                        transition: height 0.5s;
                      }
                    }
                    .icon-sidebar{
                      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(194deg) brightness(105%) contrast(105%);
                    }
                    .according-menu {
                      i {
                        color: $white;
                      }
                    }
                    span {
                      color: $white;
                      transition: all 0.3s ease;
                    }
                  }
                }
              }
            }
          }
          .simplebar-content {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .mega-box {
        &.col {
          flex-basis: unset;
        }
      }
      .according-menu {
        position: absolute;
        right: 20px;
        top: 11px;
        display: block;
        i {
          vertical-align: middle;
          color: rgba($theme-body-sub-title-color, 0.8);
          font-weight: 500;
        }
      }
      .main-menu {
        &.border-section {
          border: none;
        }
        .menu-left {
          .main-menu-right {
            .toggle-nav {
              position: absolute;
              z-index: 2;
              right: 145px;
              top: 6px;
              .sidebar-name {
                font-size: 18px;
                padding-left: 5px;
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .page-body {
        min-height: calc(100vh - 80px);
        margin-top: 110px;
        margin-left: $sidebar-compact-width;
      }
    }
    &.dark-sidebar,
    &.color-sidebar {
      .sidebar-main-title {
        h6 {
          color: $dark-all-font-color;
        }
      }
      .toggle-sidebar {
        svg {
          stroke: var(--theme-deafult);
        }
      }
      .page-body-wrapper {
        div.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              >li {
                a {
                  svg {
                    &.stroke-icon {
                      stroke: $dark-all-font-color;
                    }
                    &.fill-icon {
                      fill: $dark-all-font-color;
                    }
                  }
                  &.active {
                    svg {
                      &.stroke-icon {
                        stroke: var(--theme-deafult);
                      }
                      &.fill-icon {
                        fill: var(--theme-deafult);
                      }
                    }
                  }
                }
                &.sidebar-main-title {
                  &::before {
                    background: $dark-card-border;
                  }
                }
              }
            }
          }
        }
      }
      div.sidebar-wrapper {
        background-color: $dark-card-background;
        .sidebar-main {
          .sidebar-links {
            li {
              a {
                span {
                  color: $dark-all-font-color;
                }
                svg {
                  &.stroke-icon {
                    stroke: $dark-all-font-color;
                  }
                  &.fill-icon {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            >li {
              .mega-menu-container {
                .mega-box {
                  .link-section {
                    .submenu-title {
                      h5 {
                        color: $dark-all-font-color;
                      }
                    }
                    .submenu-content {
                      &.opensubmegamenu {
                        li {
                          a {
                            color: $dark-all-font-color;
                            font-weight: 400;
                          }
                        }
                      }
                    }
                  }
                }
                &::after {
                  background-color: $light-all-font-color;
                }
              }
              .sidebar-link {
                &.active {
                  background-color: rgba($primary-color, 0.2);
                  svg {
                    color: var(--theme-deafult);
                    stroke: var(--theme-deafult);
                  }
                }
              }
              .sidebar-submenu {
                li {
                  a {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .sidebar-list {
              ul.sidebar-submenu {
                li {
                  a {
                    span {
                      color: $dark-all-font-color;
                    }
                    &.active {
                      color: var(--theme-deafult);
                    }
                  }
                }
              }
            }
          }
        }
        .logo-wrapper {
          .for-light {
            display: none;
          }
          .for-dark {
            display: block;
          }
        }
      }
      .according-menu {
        i {
          color: $dark-all-font-color;
        }
      }
    }
    &.color-sidebar {
      div.sidebar-wrapper {
        background-color: #2f3c4e;
      }
    }
  } // compact icon css
  &.compact-sidebar {
    display: flex;
    flex-direction: column;
    .sidebar-list {
      i.fa-thumb-tack {
        visibility: hidden;
        position: absolute;
        top: 8px;
        right: 54px;
        color: $primary-color;
        width: 20px;
        height: 20px;
        background-color: $light-primary;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        [dir="rtl"] & {
          left: 54px;
          right: unset;
        }
      }
      &:hover {
        i.fa-thumb-tack {
          visibility: visible;
          cursor: pointer;
          z-index: 1;
        }
      }
      &.pined {
        order: -1;
      }
    }
    .pin-title {
      order: -1;
    }
    ~.bg-overlay1 {
      transition: 0.8s;
      &.active {
        z-index: 8;
        height: 100vh;
        width: 100vw;
        background-color: rgba($black, 0.2);
        position: fixed;
        top: 0;
      }
    }
    .toggle-sidebar {
      position: relative;
      right: unset;
      top: unset;
      margin-right: 15px;
    }
    .sidebar-main-title {
      display: none;
    }
    .logo-wrapper {
      display: none;
    }
    .logo-icon-wrapper {
      display: block;
      text-align: center;
      padding: 24px 30px;
      box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
    }
    .page-header {
      margin-left: 150px;
      width: calc(100% - 150px);
      .header-wrapper {
        .logo-wrapper {
          display: none;
        }
        .left-header {
          width: 30%;
          @media (max-width: 1578px) and (min-width:1274px) {
            width: 38%;
          }
          @media (max-width: 1273px) and (min-width:1200px) {
            width: 45%;
          }
          @media (max-width:991px) and (min-width: 768px) {
            width: 36%;
          }
          @media (max-width:767px) and (min-width:756px) {
            width: 28%;
          }
          h6 {
            @media (max-width: 1199px) {
              width: 132px;
            }
            @media (max-width:767px) {
              width: 83px;
            }
          }
        }
      }
      &.close_icon {
        margin-left: 0;
        width: calc(100% - 0px);
      }
    }
    .page-body-wrapper {
      .page-body {
        margin-top: 80px;
        margin-left: 150px;
      }
      .footer {
        margin-left: 150px;
      }
      div.sidebar-wrapper {
        position: fixed;
        top: 0;
        z-index: 9;
        >div {
          height: auto;
          line-height: inherit;
          background: $white;
          width: 150px;
          text-align: left;
          transition: 0.3s;
          box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
        }
        .sidebar-main {
          .left-arrow,
          .right-arrow {
            display: none;
          }
          .sidebar-links {
            margin: 30px 0;
            height: calc(100vh - 113px);
            overflow: auto;
            li {
              a {
                color: $dark-color;
                span {
                  text-transform: capitalize;
                  color: $dark-color;
                  font-weight: 500;
                  letter-spacing: 1px;
                  font-size: 14px;
                }
                &.active {
                  background: rgba($primary-color, 0.1);
                  color: $primary-color;
                  border-radius: 20px;
                  margin: 0 10px;
                  span {
                    color: $primary-color;
                  }
                  ~.sidebar-submenu,
                  ~.mega-menu-container {
                    animation: fadeInLeft 300ms ease-in-out;
                  }
                }
              }
              .sidebar-title {
                display: block;
                padding: 20px 0;
                border-bottom: 1px dashed #f3f3f3;
              }
              .sidebar-submenu,
              .mega-menu-container {
                position: fixed;
                top: 0px;
                height: calc(100vh - 0px);
                overflow: auto;
                left: 150px;
                width: 230px;
                background-color: $white;
                box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
                transition: all 0.5s ease-in-out;
                display: none;
                padding-top: 20px;
                z-index: -1;
                li {
                  a {
                    padding: 15px 15px 15px 40px;
                    display: block;
                    position: relative;
                    border-bottom: 1px dashed #f1f1f1;
                    .sub-arrow {
                      display: none;
                    }
                    .according-menu {
                      position: absolute;
                      right: 20px;
                      top: 16px;
                    }
                    &.active {
                      background-color: rgba($primary-color, 0.1);
                      color: $primary-color;
                      transition: all 0.3s ease;
                    }
                  }
                  .submenu-content {
                    li {
                      a {
                        &.active {
                          background-color: $transparent-color;
                        }
                      }
                    }
                  }
                }
                >li {
                  >a {
                    &::after {
                      position: absolute;
                      content: "";
                      left: 20px;
                      top: 48%;
                      width: 8px;
                      border-top: 2px dashed rgba($theme-body-sub-title-color, 0.4);
                    }
                  }
                }
                .mega-box {
                  flex: 0 0 100%;
                  max-width: 100%;
                  padding: 0;
                  .link-section {
                    text-align: left;
                    .submenu-title {
                      h5 {
                        position: relative;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 15px 15px 15px 40px;
                        letter-spacing: 1px;
                        margin-bottom: 0;
                        &::after {
                          position: absolute;
                          content: "";
                          left: 20px;
                          top: 48%;
                          width: 6px;
                          border-top: 2px dashed #595c60;
                        }
                      }
                    }
                  }
                }
              }
            }
            .sidebar-list {
              position: relative;
              .badge {
                position: absolute;
                left: 53%;
                top: 7px;
                font-weight: 500;
                font-size: 9px;
              }
              .sidebar-link {
                text-align: center;
                svg {
                  margin: 0 auto 6px;
                  width: 24px;
                  height: 24px;
                }
                >span {
                  display: block;
                  font-weight: 500;
                  letter-spacing: 1px;
                  font-size: 14px;
                  word-break: break-word;
                }
                .according-menu {
                  display: none;
                }
              }
            }
            .mega-menu {
              text-align: center;
              padding: 0;
              svg {
                width: 24px;
                height: 24px;
              }
              .according-menu {
                position: absolute;
                right: 20px;
                top: 13px;
              }
            }
          }
        }
        &[data-layout="stroke-svg"] {
          .sidebar-links {
            .mega-menu {
              svg {
                &.stroke-icon {
                  display: block;
                  margin: 0 auto 6px;
                }
                &.fill-icon {
                  display: none;
                }
              }
            }
          }
        }
        &[data-layout="fill-svg"] {
          .sidebar-links {
            .mega-menu {
              svg {
                &.stroke-icon {
                  display: none;
                }
                &.fill-icon {
                  display: block;
                  margin: 0 auto 6px;
                }
              }
            }
          }
        }
        &.close_icon {
          transform: translateX(-150px);
          ~.page-body,
          ~.footer {
            margin-left: 0px;
          }
        }
      }
    }
  } // compact small css
  &.compact-small {
    .toggle-sidebar {
      position: relative;
      right: unset;
      top: unset;
      margin-right: 15px;
    }
    .sidebar-list {
      i.fa-thumb-tack {
        visibility: hidden;
        position: absolute;
        top: 8px;
        right: 22px;
        color: $primary-color;
        width: 20px;
        height: 20px;
        background-color: $light-primary;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        [dir="rtl"] & {
          left: 22px;
          right: unset;
        }
      }
      &:hover {
        i.fa-thumb-tack {
          visibility: visible;
        }
      }
      &.pined,
      &.pin-title {
        order: -1;
      }
    }
    .sidebar-main-title,
    .logo-wrapper {
      display: none;
    }
    .logo-icon-wrapper {
      display: block;
      text-align: center;
      padding: 27px 30px;
      box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
    }
    .page-header {
      margin-left: 90px;
      width: calc(100% - 90px);
      .header-wrapper {
        .logo-wrapper {
          display: none;
        }
      }
      &.close_icon {
        margin-left: 0;
        width: calc(100% - 0px);
      }
    }
    .page-body-wrapper {
      .page-body {
        margin-left: 90px;
      }
      .footer {
        margin-left: 90px;
      }
      div.sidebar-wrapper {
        >div {
          width: 90px;
        }
        .sidebar-main {
          .sidebar-links {
            @media (max-width: 420px) {
              height: calc(100vh - 105px);
            }
            li {
              .sidebar-title {
                display: block;
                padding: 15px 0;
              }
              .sidebar-submenu,
              .mega-menu-container {
                left: 90px;
                li {
                  a {
                    padding: 10px 15px 10px 40px;
                    .sub-arrow {
                      display: none;
                    }
                    .according-menu {
                      top: 11px;
                    }
                  }
                }
              }
            }
            .sidebar-list {
              .badge {
                display: none;
              }
              .sidebar-link {
                svg {
                  margin: 0 auto;
                  width: 20px;
                  height: 20px;
                }
                >span {
                  display: none;
                }
              }
            }
            .mega-menu {
              svg {
                margin: 0 auto;
              }
              a {
                >span {
                  display: none;
                }
              }
            }
          }
        }
        &.close_icon {
          transform: translateX(-150px);
          ~.page-body,
          ~.footer {
            margin-left: 0px;
          }
        }
      }
    }
  } // only body css
  &.only-body {
    background-color: #f2f0fa;
    .page-body-wrapper {
      background-color: #f2f0fa;
    }
    .page-header {
      position: relative;
      .header-logo-wrapper {
        display: none;
      }
      .header-wrapper {
        padding: 0 !important;
        .left-header {
          display: none;
        }
        .nav-right {
          position: absolute;
          right: 60px;
          top: 58px;
        }
        .search-full {
          top: 37px;
          width: calc(100% - 50px);
          left: 25px;
          [dir="rtl"] & {
            left: unset;
            right: 25px;
          }
          .form-group {
            .Typeahead {
              .u-posRelative {
                background-color: $white;
                border-radius: 15px;
              }
            }
          }
          .Typeahead-menu {
            top: 78px;
            width: calc(100% - 30px);
            margin: 0 auto;
            right: 0;
          }
        }
      }
    }
    .page-body-wrapper {
      .page-title {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    .sidebar-wrapper,
    footer {
      display: none;
    }
    .page-body-wrapper {
      .page-body {
        margin-top: 0px;
        min-height: calc(100vh - 0px);
      }
      .page-title {
        padding-left: 30px;
        padding-right: 30px;
        margin: 30px 0;
        border-radius: 15px;
        @media (max-width: 991.98px) {
          padding-left: 20px;
          padding-right: 20px;
          margin: 20px 0;
        }
        >.row {
          .col-6 {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
        .breadcrumb {
          margin-top: 5px;
          justify-content: flex-start;
        }
      }
    }
  } // modern css
  &.modern-type {
    .progress-chart-wrap {
      margin-left: -22px;
      [dir="rtl"] & {
        margin-left: unset;
        margin-right: -22px;
      }
    }
    .ecommerce-widget {
      border: 1px dashed lighten($primary-color, 22%) !important;
    }
    .file-sidebar .pricing-plan {
      border: 1px dashed lighten($primary-color, 22%) !important;
    }
    .alert-primary {
      color: $primary-color;
    }
    .project-box {
      background-color: lighten($primary-color, 22%);
    }
    .nav-tabs .nav-link.active {
      background-color: transparent;
    }
    .simplebar-mask {
      top: 10px;
    }
    .page-header {
      margin-left: 0px;
      width: calc(100% - 0px);
      &.close_icon {
        margin-left: 0px;
        width: calc(100% - 0px);
      }
      .header-wrapper {
        .left-header {
          width: 30%;
          @media (max-width: 1428px) and (min-width:1200px) {
            width: 37%;
          }
          @media (max-width:818px) {
            width: 36%;
          }
        }
        .nav-right {
          @media (max-width: 1669px) {
            width: 50%;
          }
          @media (max-width:1428px) and (min-width:1200px) {
            width: 40%;
          }
        }
        .header-logo-wrapper {
          display: flex;
          align-items: center;
        }
        .logo-wrapper {
          display: block;
          margin-right: 30px;
        }
        .toggle-sidebar {
          display: block;
          position: relative;
          top: unset;
          right: unset;
          margin-right: 15px;
        }
      }
    }
    .page-body-wrapper {
      .page-title {
        background-color: transparent;
        padding-bottom: 0;
      }
      div.sidebar-wrapper {
        margin: 30px !important;
        top: 80px;
        bottom: 0;
        border-radius: 15px;
        .logo-wrapper,
        .logo-icon-wrapper {
          display: none;
        }
        .sidebar-main {
          .sidebar-links {
            height: calc(100vh - 160px);
          }
        }
        &.close_icon {
          .logo-icon-wrapper {
            display: none;
          }
          ~.page-body {
            margin-left: 0px;
          }
          ~footer {
            margin-left: 0px;
            margin-right: 0px;
          }
          &:hover {
            .logo-wrapper {
              display: none;
            }
          }
        }
      }
      .page-body {
        margin-left: 296px;
        .container-fluid {
          max-width: 1500px;
        }
      }
      .footer {
        margin-left: 360px;
        margin-right: 70px;
        border-radius: 10px;
        @media (max-width: 991px) {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  } // material css
  &.material-type {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      left: 0;
      width: 100%;
      height: 300px;
      background-image: $gradient-bg-primary;
    }
    .card {
      box-shadow: 0 0 20px rgba($black, 0.05);
    }
    .page-header {
      position: relative;
      max-width: 1560px;
      margin: 50px auto 0;
      border-radius: 15px 15px 0 0;
      .header-wrapper {
        .left-header {
          width: 31%;
          @media (max-width: 1600px) {
            width: 33%;
          }
          @media (max-width:1366px) {
            width: 38%;
          }
          @media (max-width:1199px) {
            width: 30%;
          }
        }
        .nav-right {
          @media (max-width: 1600px) {
            width: 40%;
          }
          @media (max-width:1199px) {
            width: 45%;
          }
        }
      }
    }
    .page-body-wrapper {
      background-image: url(../../images/other-images/boxbg.jpg);
      background-blend-mode: overlay;
      background-color: rgba($white, 0.5);
      .sidebar-wrapper {
        position: relative;
        max-width: 1560px;
        margin: 0 auto;
        top: unset;
        height: 56px;
      }
      .page-body {
        margin: 0 auto;
        max-width: 1560px;
        background-color: $body-color;
        box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
        border-radius: 0 0 15px 15px;
      }
    }
    .footer {
      margin-top: 40px;
    }
    &.compact-wrapper {
      .page-header {
        .header-wrapper {
          .left-header {
            width: 38%;
          }
          .nav-right {
            @media (max-width: 991px) {
              width: 50%;
            }
          }
        }
      }
    }
  }
  &.advance-layout {
    background-blend-mode: overlay;
    background-color: $main-body-color;
    .card {
      border-radius: 5px;
      box-shadow: none;
      border: 1px dashed #ecf3fa;
    }
    .page-header {
      position: relative;
      background-color: $white;
      margin: 0 auto;
    }
    .page-body-wrapper {
      background: transparent;
      .sidebar-wrapper {
        position: relative !important;
        top: unset !important;
        height: unset;
        box-shadow: none;
        border-top: 1px dashed #efefef;
        border-bottom: 1px dashed #efefef;
        >div {
          overflow-x: hidden;
        }
      }
      .page-body {
        margin-top: 0 !important;
      }
    }
  }
}

@keyframes pagebody {
  from {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
  }
  50% {
    opacity: 1;
  }
}

// sidebar filter
.md-sidebar {
  position: relative;
  .md-sidebar-toggle {
    display: none;
    width: fit-content;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1480px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              >li {
                .mega-menu-container {
                  right: -18%;
                  width: 91%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1460px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              >li {
                .mega-menu-container {
                  right: -54%;
                  width: 75%;
                }
              }
            }
          }
        }
      }
    }
  }
  .sidebar-wrapper {
    .sidebar-main {
      .sidebar-links {
        >li {
          margin-right: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              >li {
                .mega-menu-container {
                  right: -49%;
                  width: 70%;
                }
              }
            }
          }
        }
      }
    }
  }
  .profile-dropdown {
    &.onhover-show-div {
      right: 0;
      left: unset !important;
      &:before,
      &:after {
        right: 10px !important;
        left: unset !important;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          >ul {
            >li {
              padding: 6px;
            }
          }
        }
      }
    }
  }
  .mobile-title {
    &.d-none {
      display: flex !important;
      justify-content: space-between;
      padding: 20px 30px 17px;
      border-bottom: 1px dashed #ddd;
      margin: 0 -15px;
      h5 {
        color: var(--theme-deafult);
      }
      svg {
        stroke: var(--theme-deafult) !important;
      }
    }
  }
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              >li {
                .mega-menu-container {
                  right: -35%;
                  width: 56%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .page-header {
    .header-wrapper {
      .nav-right {
        .notification-dropdown {
          top: 46px;
        }
      }
    }
  }
  .responsive-btn {
    display: none;
  }
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-main {
          .sidebar-links {
            .simplebar-mask {
              top: 0;
            }
            >li {
              .sidebar-submenu {
                width: 200px;
                opacity: 0;
                visibility: hidden;
                border-radius: 5px;
                transition: all 0.5s ease;
                a {
                  .sub-arrow {
                    position: absolute;
                    right: 20px;
                    top: 5px;
                    color: $light-semi-font;
                    font-weight: 100;
                    transition: all 0.5s ease;
                    font-size: 15px;
                    top: 5px;
                  }
                }
                li {
                  .nav-sub-childmenu {
                    display: none;
                    border-radius: 4px;
                  }
                  &:hover {
                    .nav-sub-childmenu {
                      display: block;
                      position: absolute;
                      left: 200px;
                      background-color: $white;
                      top: -10px;
                      padding: 15px 0px 15px 0px;
                      box-shadow: 0 0 1px 0 #898989;
                    }
                  }
                  a {
                    &:hover {
                      .sub-arrow {
                        right: 16px;
                        transition: all 0.5s ease;
                      }
                    }
                  }
                }
              }
              .mega-menu-container {
                opacity: 0;
                visibility: hidden;
                transition: all 0.5s ease;
              }
              &.hoverd {
                .sidebar-submenu {
                  opacity: 1;
                  visibility: visible;
                  margin-top: 8px;
                  transition: all 0.5s ease;
                }
              }
              &:hover {
                .mega-menu-container {
                  opacity: 1;
                  visibility: visible;
                  margin-top: 20px;
                  transition: all 0.5s ease;
                }
              }
            }
          }
        }
        .toggle-nav {
          display: none;
        }
        .mobile-back {
          display: none !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  // material icon css
  .page-wrapper {
    &.material-icon {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        left: 0;
        width: 100%;
        height: 200px;
        background: $gradient-bg-primary;
      }
      .toggle-sidebar {
        color: rgba($white, 0.8);
        &:hover {
          >svg {
            color: $white !important;
            stroke: $white !important;
          }
        }
      }
      .page-header {
        position: relative;
        background: transparent;
        padding-left: 90px;
        max-width: 1660px;
        margin: 0 auto;
        width: calc(100% - 0px);
        box-shadow: none;
        .f-light {
          color: $white;
        }
        .header-wrapper {
          .nav-right {
            ul {
              li {
                svg {
                  stroke: $white;
                }
                .profile-dropdown {
                  li {
                    svg {
                      stroke: $theme-body-font-color;
                    }
                  }
                }
              }
            }
            >ul {
              >li {
                color: $white;
                .notification-box,
                .cart-box {
                  svg {
                    stroke: $white;
                  }
                }
                >a {
                  >svg {
                    stroke: $white;
                  }
                }
                >svg {
                  stroke: $white;
                }
              }
            }
            .language-nav {
              .more_lang {
                color: $theme-body-font-color;
              }
            }
            .onhover-show-div {
              color: $theme-body-font-color;
            }
            &.right-header {
              ul {
                li {
                  .profile-media {
                    .flex-grow-1 {
                      p {
                        color: rgba($white, 0.7);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.close_icon {
          padding-left: 0;
          margin: 0 auto !important;
        }
      }
      .page-body-wrapper {
        .page-body {
          margin-top: 0 !important;
          padding-left: 90px;
          max-width: 1660px;
          margin: 0 auto;
        }
        div.sidebar-wrapper {
          &.close_icon {
            ~.page-body {
              padding-left: 15px;
              margin: 0 auto !important;
            }
          }
        }
        .page-title {
          color: $white;
          background-color: transparent;
          border-bottom: none;
          box-shadow: none;
          .breadcrumb {
            .breadcrumb-item {
              a {
                svg {
                  stroke: $white;
                }
              }
              &.active {
                color: rgba($white, 0.6);
              }
              +.breadcrumb-item {
                &::before {
                  color: rgba($white, 0.8);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  // material icon css
  .page-wrapper {
    &.material-icon {
      &::before {
        height: 240px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .sidebar-wrapper {
    .menu {
      .brand-logo {
        img {
          width: 120px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .toggle-nav {
    i {
      font-size: 20px;
    }
  }
}

/**=====================
  5.7 Sidebar CSS Ends
==========================**/
