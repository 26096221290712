/**=====================
    2.1 According CSS start
==========================**/
.default-according {
    .avatar-showcase {
        margin-bottom: 0;
    }

    .card {
        margin-bottom: 0;
        box-shadow: none;

        &+.card {
            margin-top: $according-card-top-margin;
        }

        .btn-link {
            font-weight: $according-btn-weight;
            color: $theme-body-font-color;
            text-decoration: none;
        }

        .btn-link {

            &:focus,
            &:hover {
                text-decoration: none;
            }
        }

        .card-header {
            padding: $according-card-header-padding;
            border: $card-border-color;

            i {
                position: absolute;
                left: 18px;
                font-size: 20px;
                top: 20px;
            }

            h5 {
                margin-top: 2px;
            }
        }

        .card-body {
            border: $card-border-color;
            border-top: none;
        }
    }
}

.default-according.style-1 {
    button {
        width: 100%;
        text-align: left;

        &:before {
            right: 20px;
            position: absolute;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            font-size: 20px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

            @media (max-width: 575px) {
                right: 15px;
            }
        }

        &[aria-expanded="true"] {
            &:before {
                content: $according-open-icon;
                font-family: $according-card-header-icon;
            }
        }

        &[aria-expanded="false"] {
            &:before {
                content: $according-close-icon;
                font-family: $according-card-header-icon;
            }
        }
    }
}

.accordion-button {
    .svg-color {
        position: absolute;
        right: 20px;

        [dir="rtl"] & {
            left: 20px;
            right: unset;
        }

        &::after {
            content: '';
            background-image: unset;
        }
    }

    &:not(.collapsed) {
        background-color: unset;
        box-shadow: none;

        &::after {
            background-image: unset;
        }
    }

    &::after {
        background-image: unset;
    }

    &:focus {
        box-shadow: none;
    }
}

$accordion-light-name: primary,
    secondary,
    success,
    warning,
    info,
    light,
    danger,
    dark;

$accordion-light-color: $light-primary,
    $light-secondary,
    $light-success,
    $light-warning,
    $light-info,
    $light-white,
    $light-danger,
    $light-dark;

@each $var in $accordion-light-name {
    $i: index($accordion-light-name, $var);

    .accordion-button {
        &.accordion-light-#{$var} {
            &:not(.collapsed) {
                background-color: nth($accordion-light-color, $i);
            }

            &:active,
            &.active {
                background-color: nth($accordion-light-color, $i);
                color: $black;
            }
        }
    }

    .multi-collapse {
        &.show {
            .collapse-wrapper {
                &.accordion-light-#{$var} {
                    background-color: nth($accordion-light-color, $i) !important;
                }
            }
        }
    }
}

.accordion-item {
    .accordion-button {
        .svg-wrapper {
            width: 18px;
            height: 18px;
        }
    }
}

.accordion-wrapper {
    &:active {
        border: 1px dashed $primary-color;
        border-top: 1px dashed $primary-color  !important;
    }
}

.accordion-border {
    .accordion-item {
        border: 0;
    }
}


.icons-accordion {
    .accordion-item {
        &:last-child {
            .accordion-body {
                padding-bottom: 0 !important;
            }
        }
    }
}

.accordions-content {
    li {
        color: $dark-color;
    }
}


.collapse-accordion {
    .btn-dark {
        &.show {
            border-radius: 15px;
        }
    }
}

.accordion{
    .accordion-item{
      border: none;
    }
  }

/**=====================
    2.1 According CSS Ends
==========================**/